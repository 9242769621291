@import "../../../../style/consts.scss";

.card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: $background-color-left;
    width: 30%;
    height: auto;
    overflow: hidden;
    border-radius: .75rem;
    margin: 1rem 0;
    box-shadow: 0.25rem 0.75rem 0.75rem $box-shadow;

    .card-img-container {
        overflow: hidden;
        aspect-ratio: 16/9;
    }

    .card-img {
        width: 100%;
    }

    .card-bio {
        display: flex;
        justify-content: space-between;
    }

    .right-bio {
        margin: 0.5rem 1.25rem;
        font-weight: 700;
    }

    .left-bio {
        font-size: .75rem;
        margin: 0.5rem 1.25rem;
        font-weight: 500;
    }
}

@media screen and (max-width: $breakpoint) {
    .card {
        width: 100%;
    }

}