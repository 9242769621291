@import "./consts.scss";

.main-container {
    width: 100vw;
    height: -webkit-fill-available;
    box-sizing: border-box;
    padding-right: 10vw;
    background-color: white;
    height: calc(100vh - #{$header-height});

    &.server-loading {
        pointer-events: none;
    }

    .content-container {
        width: 100%;
        height: inherit;
        display: flex;

        &::-webkit-scrollbar {
            display: none;
        }

        .left-side {
            width: 35vw;
            color: $texts;
        }

        .right-side {
            flex: 1;
            height: 100%;
            overflow-y: scroll;
            color: $texts;
            max-height: inherit;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

@media (max-width: $breakpoint) {
    .main-container {
        height: auto;
        background-color: unset;
        @include scrollBar-thin;
        padding-right: 0;

        .content-container {
            flex-direction: column-reverse;
            justify-content: flex-end;
            height: fit-content;

            .left-side {
                display: none;
            }

            .right-side {
                width: 100%;
                height: fit-content;
                width: min-content;
                margin: 0 auto;
            }
        }
    }
}
