@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap");

$texts: #323232;
$texts-secondary: #41596b;
$box-shadow: #00000029;
$dark-blue: #0c414f;
$box-shadow-light: #4a4a4a26;
$header: #ffffff;
$background-color-primary: #ffffff;
$background-color-secondary: #f5f7f8;
$background-color-left: #f5f7f8;
$error: #fd003c;
$btn-yellow: #eda424;
$btn-blue: #95c1ce;
$btn-cyan: #0c414f;
$event-strip-background: #f5f7f8;
$footer-background: #d7e0e2;
$popup-background: #41596b94;
$check-box-radio: #3e3e3e;
$inputs-background-color: #f5f7f8;
$selected-background-inputs: #95C1CE;
$inputs-border: #afc0c3;
$scroll-color: #ececf2;
$light-gray: #707070;
$lighter-gray: #8b8b8b;
$dark-gray: #323232BF;
$yellow: #ffe082;
$transparent-white: #ffffffc6;
$time-slider-background: #ededed;
$font: "Assistant", sans-serif;
$breakpoint: 768px;
$event-image-ratio: 3;

$header-height: 11vh;
$header-height-mobile: 7.6vh;

@function vh($quantity) {
    @return calc(var(--vh, 1vh) * #{$quantity});
}

@mixin scrollBar-thin {
    &::-webkit-scrollbar {
        width: 0.2vw;
    }

    &::-webkit-scrollbar-track {
        width: 0.2vw;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scroll-color;
    }
}

@mixin no-scrollBar {
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin input-error {
    color: $error;
    font-size: vh(1.7);
}

@mixin thick-scrollBar {
    &::-webkit-scrollbar {
        width: 0.4vw;
    }

    &::-webkit-scrollbar-track {
        background-color: $event-strip-background;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $background-color-left;
        border-radius: vh(10);
    }
}

@mixin titles {
    .title {
        font-size: vh(3.5);
        font-weight: 700;
        margin-bottom: 0.5vh;
        color: $dark-blue;
    }

    .second-title {
        font-size: vh(2.5);
        color: #787878;
        font-weight: 500;
    }

    @media screen and (max-width: $breakpoint) {
        .title {
            font-size: vh(2.8);
        }

        .second-title {
            font-size: vh(2);
        }
    }
}

@mixin system-home-page-section-styles {
    padding-right: 7vw;
    padding-top: 5vh;
    padding-bottom: 5vh;

    .title {
        font-size: vh(3.5);
        font-weight: 700;
        margin-bottom: 0.5vh;
        color: #0c414f;
    }

    .second-title {
        font-size: vh(2.5);
        color: #787878;
        font-weight: 500;
    }

    @media screen and (max-width: $breakpoint) {
        .title {
            font-size: vh(2.4);
        }

        .second-title {
            font-size: vh(2);
        }
    }
}

@mixin content-pages-section-styles {
    padding-right: 7vw;
    padding-top: vh(7);
}