@import "../../../../style/consts.scss";

.add-new-event-right {
    width: min-content;

    .add-event-title {
        font-weight: 700;
        font-size: vh(4.8);
        margin-top: vh(3);
        margin-bottom: vh(2);
        align-self: flex-start;
        text-align: right;
    }
}

@media (max-width: $breakpoint) {
    .add-event-title {
        font-size: vh(3.8);
    }
}
