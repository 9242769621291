@import "../../../../style/consts.scss";

.organization-swiper-event-cover {
    height: 100%;
    background-color: #323232d9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: vh(3.5);

    img {
        width: 5vh;
        margin-bottom: 2vh;
    }

    .time-left {
        font-weight: 800;
        font-size: vh(4.5);
    }
}
