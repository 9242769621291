@import "../../../style/consts.scss";

.order-status {
    color: $texts;
    background-color: $header;
    width: 30%;
    box-shadow: 0px 3px 16px $box-shadow-light;
    padding: vh(6);
    margin-right: vh(2);
    min-height: 100%;
}

.status-order-title,
.bold {
    font-size: vh(3);
    font-weight: 700;
}

.timeline-item-content {
    margin-top: vh(-5);
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    .name-and-info-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.7vw;

        .organization-info-icon {
            margin-top: 3vh;
        }
    }

    span {
        display: flex;
        align-items: center;
        font-size: vh(2);
        font-weight: 400;
        margin-top: vh(-2)
    }
}

.disabled {
    opacity: 0.7;
    color: $lighter-gray;
    filter: grayscale(100%);
}

.ticket-status-order-icon {
    margin-left: vh(1);
    width: 1.6vw;
    height: auto;
}

.timeline-img,
.timeline-img-first {
    width: vh(7);
    height: vh(7);
}

.timeline-img {
    border: 1px solid $lighter-gray;
}

.timeline-img-first {
    border: 2px solid $yellow;
}

.MuiTimelineConnector-root {
    height: vh(8);
}

.status-order-summary {
    font-size: vh(2.2);
    font-weight: 500;

    span {
        display: block;
        font-size: vh(2);
        font-weight: 400;
    }
}

@media screen and (max-width: $breakpoint) {
    .order-status {
        box-shadow: 0 -5px 9px -5px $box-shadow-light;
    }

    .status-order-title,
    .bold {
        font-size: vh(2);
    }

    .timeline-item-content {
        margin-top: vh(-4);

        .name-and-info-row {
            gap: 1vw;

            .organization-info-icon {
                margin-top: vh(2);
            }
        }
    }

    .ticket-status-order-icon {
        width: 4vw;
    }
}