@import "./consts.scss";

.categories-input {
    cursor: pointer;
    margin-bottom: vh(1);

    .add-category-btn {
        padding: vh(0.5);
        margin-right: 0.7%;

        img {
            width: 3vh;
        }
    }
}

.input-container {
    .input-error {
        color: $error;
        transition: 0.3s;
        min-height: 3vh;
        text-align: right;
        font-weight: 400;
        font-size: 1.7vh;
    }
}


