@import "./consts.scss";

@keyframes slide-in-animation {
    0% {
        width: 0%;
        opacity: 0.5;
    }

    100% {
        width: 65%;
        opacity: 1;
    }
}


@keyframes icon-reveal {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.coupon {
    .coupon-container {
        display: flex;
        width: 40vw;
        justify-content: space-between;
        align-items: center;
        font-size: 2.6vh;
        font-weight: 600;
        height: 11vh;
        gap: 20%;

        .right-container {
            display: flex;
            flex: 2;
            justify-content: space-between;
        }

        .copy-column {
            display: flex;
            flex: 1;
            align-items: flex-end;
            transform: translateX(-5vw);
            justify-content: flex-start;

            .copy-coupon {
                cursor: pointer;
            }

            .coupon-copy-icon {
                width: 1.8vw;
            }

            & .check-icon {
                opacity: 0;
                animation: icon-reveal 0.5s ease-in-out;
                animation-fill-mode: forwards;
            }

            .copied-text {
                direction: ltr;
                font-size: 2vh;
                white-space: nowrap;
                overflow: hidden;
                opacity: 0;
                animation: slide-in-animation 0.4s ease-in-out 0.5s;
                animation-fill-mode: forwards;
                font-weight: lighter;
                color: $dark-blue;
            }
        }


    }

    .the-button {
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
}

@media screen and (max-width: $breakpoint) {
    .coupon {
        .description {
            width: 80vw;
        }

        .coupon-container {
            width: 62vw;
            font-size: 2vh;

            .right-container {
                flex: 3;
            }

            .copy-column {
                align-items: flex-end;

                .coupon-copy-icon {
                    width: 4vw;
                }
            }
        }

        .the-button {
            .big {
                width: 50vw;
            }
        }
    }
}