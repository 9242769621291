@import "../../../../style/consts.scss";

.greeting-wrapper {
    background-color: $background-color-primary;
    height: vh(12);

    .greeting-text {
        font-size: vh(2.3);
        padding-top: vh(4);
        padding-right: 6vw;

        .name-of-organization {
            font-weight: 700;
            padding: 0 1vw;
        }
    }
}