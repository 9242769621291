@import "./consts.scss";

.event-details-card {
    width: 18vw;
    height: vh(38);
    box-sizing: border-box;
    background-color: white;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 3px;
    cursor: pointer;
    padding: 2vh 2vh;
    color: #0c414f;
    display: flex;
    flex-direction: column;

    .event-cover-div {
        aspect-ratio: 2;
        width: 100%;
        height: 50%;
        background-color: #0c414f;
        overflow: hidden;
        margin-bottom: 1vh;
        position: relative;

        .event-cover-wrapper {
            position: absolute;
            right: 50%;
            transform: translateX(50%);
        }
    }

    .event-title {
        font-size: vh(3);
        font-weight: 700;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .event-date {
        font-size: vh(2);
    }

    .event-details-container {
        font-size: vh(1.8);
        margin-top: auto;

        .event-details {
            margin-bottom: 0.5vh;

            &.with-icon {
                &>* {
                    vertical-align: middle;
                }

                img {
                    width: 2.2vh;
                    margin-left: 1vh;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .event-details-card {
        width: 80vw;
        height: 40vh;
        padding: 5vw;
    }
}