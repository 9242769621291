@import "../../../../style/consts.scss";

.contact-us-page {
    @include titles;

    .contact-us-right {
        display: flex;
        flex-direction: column;

        .contact-us-right-container {
            width: 30.2vw;
            display: flex;
            flex-direction: column;
            text-align: right;
            padding-top: vh(5);

            .contact-us-right-title {
                color: $texts;
                font-weight: 700;
                font-size: vh(4.8);
                margin-top: vh(6);
                margin-bottom: vh(3.5);
            }

            .contact-us-inputs {
                .contact-us-right-input {
                    margin-bottom: vh(1);
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .main-container {
        background-color: $background-color-primary;
    }

    .contact-us-page {
        .contact-us-right {
            width: 85vw;

            .contact-us-right-container {
                width: 100%;
                overflow: hidden;
                height: 87vh;
            }
        }
    }
}
