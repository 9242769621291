@import "../../../style/consts.scss";

.time-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.35px;
    color: $dark-blue;
}

.time-slider-text {
    margin-left: vh(1);
    font-size: vh(3.4);
}

.time-slider-time {
    font-size: vh(4.5);
    font-family: $font;
    font-weight: 800;
}

.add-time-button-wrapper {
    margin-right: 1vw;

    .add-time-button {
        border-radius: vh(20);
        font-weight: 700;
        color: $texts;
        border: 1px solid $dark-blue;

        .items-in-add-time {
            display: flex;
            gap: vh(1);
            flex-direction: row-reverse;
            align-items: center;
            font-size: vh(2.5);

            .add-time-icon {
                width: vh(3);
            }
        }
    }
}

.time-slider-progress-bar-yes {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $dark-blue;
    height: vh(1.5);
    border-radius: 0 1.5vw 1.5vw 0;
    transition: width 1s ease-in-out; //animtation nati added
}

@media (max-width: 760px) {
    .time-slider {
        height: 4.5vh;

        .time-slider-text,
        .time-slider-time {
            font-size: vh(2.5);
        }

        .add-time-button-wrapper {
            margin-right: 2vw;

            .add-time-button {
                min-width: min-content;
                width: auto;
                aspect-ratio: 1;
                border: 0;
            }
        }
    }

    .time-slider-progress-bar-yes {
        height: vh(1);
    }
}
