@import "../../../../style/consts.scss";

.radio-buttons-item {
    margin-bottom: vh(1.5);
}

.place-flex-container {
    display: flex;
    gap: vh(0.5);
    align-items: center;
}

.place-item {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    font-size: vh(2);

    .place-item-data {
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: vh(1);
        margin-bottom: vh(4);
        width: 100%;
    }

    .info-icon {
        width: vh(2);
        height: auto;
    }

    .place-item-name {
        font-weight: 700;
        margin-bottom: vh(0.5);
    }
}

.place-item:last-child > .place-item-data {
    // cancel last margin-bottom of last place-item
    margin-bottom: 0;
}

@media screen and (max-width: $breakpoint) {
    .place-item {
        font-size: vh(2);
    }
}
