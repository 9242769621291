@import "../../../style/consts.scss";

.give-by {
    position: absolute;
    right: 0;
    bottom: 2vh;
    display: flex;
    justify-content: space-evenly;
    width: fit-content;
    min-width: 10vw;
    height: vh(8);
    align-items: center;
    background: rgba(255, 255, 255, 0.76) 0% 0% no-repeat padding-box;
    border-radius: 12px 0px 0px 12px;
    padding-left: 1vw;
    // opacity: 0.76;
    color: $texts;
    font-size: vh(2.8);
    box-shadow: 0px 3px 16px #b7b7b7;
    z-index: 2;

    .give-by-text {
        text-align: center;
        font-family: $font;
        letter-spacing: 0px;
        opacity: 1;
        margin-left: 0.6vw;
        margin-right: 1vw;
    }

    .give-by-logo {
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
    }

    .give-by-text {
        margin-right: 0.3vw;
        min-width: 7vw;
        text-align: right;
        font-family: $font;
        font-weight: 700;
        letter-spacing: 0px;
        margin-left: 1w;
        opacity: 1;
        margin-right: 0.6vw;
    }
}

@media screen and (max-width: $breakpoint) {
    .give-by {
        font-size: vh(1.5);
        height: vh(5);

        .profile-pic-wrapper {
            height: vh(3);
            width: vh(3);
        }
    }

}