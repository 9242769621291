@import "../../../../style/consts.scss";

.vip-container {
    font-size: vh(2.3);
    max-width: vw(85);
    width: 100%;
    margin-top: vh(3);

    .title-vip {
        font-weight: 700;
        font-size: 2.4vh;
    }

    .vip-explain {
        width: 30vw;
    }

    .other-option-vip-text {
        margin-top: -4vh
    }
}

.contact-enter {
    font-size: vh(2);
    font-weight: 800;

    .enter-email {
        font-weight: 400;
        border-bottom: 0.8px solid black;
        cursor: pointer;
    }
}

.coppied {
    font-size: vh(2);
    margin-top: 1vh;
    color: $dark-blue;
}

@media screen and (max-width: $breakpoint) {
    .vip-container {
        .vip-explain {
            width: 90vw
        }
    }
}