@import "../../style/consts.scss";

.demographic {
    width: 80vw;
    height: 87vh;

    .category-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .title-img {
            width: 4vw;
        }

        .title {
            font: normal normal bold vh(3.8) / vh(4.8) Assistant;
            color: $texts;
            margin-top: vh(-3);
        }

        form {
            width: 100%;
        }

        .demog-box {
            height: vh(64);
            width: 100%;
            margin-top: vh(3);
            display: grid;
            grid-template-columns: repeat(auto-fill, 30%);
            direction: rtl;
            justify-content: space-evenly;
            margin-bottom: 1.5vh;

            @include scrollBar-thin;

            .category-item {
                direction: ltr;
                width: 100%;
                justify-self: center;
                background: $inputs-background-color;
                border: 1px solid $inputs-border;
                height: vh(8);
                margin: vh(1);
                display: flex;
                flex-direction: row-reverse;
                align-items: center;

                .category-check {
                    margin-right: 2vw;
                    margin-left: 2vw;
                }

                .category-name {
                    font-family: $font;
                    max-width: 65%;
                    text-align: right;
                    font-size: 1.3vw;
                    font-size: 1vw;
                    overflow-wrap: break-word;
                }
            }

            .no-found-type {
                font-size: vh(3);
                overflow-wrap: break-word;
                color: $texts;
                font-family: $font;
                font-weight: 500;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
    }
}

@media (max-width: 1100px) {
    .demographic {
        .category-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .title {
                font-size: 3.5vw;
            }

            .demog-box {
                height: 54vh;
                grid-template-columns: unset;
                margin-bottom: unset;

                .category-item {
                    .category-name {
                        font-size: 1.8vw;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint) {
    .demographic {
        height: 80vh;

        .category-container {
            .title {
                font-size: 5.5vw;
            }

            .demog-box {
                margin-top: unset;
                height: 57vh;
                display: flex;
                justify-content: unset;
                overflow: scroll;
                flex-direction: column;
                align-items: center;
                margin-bottom: 2vh;

                .category-item {
                    width: 65vw;
                    padding: 1vh 0;

                    .category-name {
                        max-width: unset;
                        font-size: 4vw;
                        margin-right: 2vw;
                    }
                }
            }
        }
    }
}