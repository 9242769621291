@import "../../../../style/consts.scss";

.feedback-container {
    @include system-home-page-section-styles;

    .title {
        width: max-content;
    }

    .feedback-link {
        color: #323232a8;
        font-weight: 500;
        font-size: 2vh;
    }

    .main {
        flex: 4;
    }

    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .link {
        flex: 1;
    }

    .feedbacks-slider {
        margin-right: -7vw;
        width: 100vw;
        padding-bottom: 5.8vh;
    }

    .feedbacks-list-container {
        margin-top: 3vh;
        max-height: 68vh;
        width: 50vw;
        overflow-y: scroll;
        @include scrollBar-thin;

        .feedbacks-container {
            border-spacing: 0 2vh;
        }
    }
}
