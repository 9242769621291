@import "../../../style/consts.scss";

.slider-element {
    position: relative;
    width: min-content;

    .slide-content {
        background-color: $background-color-left;
        border-radius: vh(1);
        width: 40vw;
        color: $texts;
        text-align: right;
        margin-top: vh(2);
        margin-bottom: vh(2);
        box-shadow: -3px 3px 6px $box-shadow;
        overflow: hidden;

        .swiper-pics {
            cursor: pointer;
            width: 100%;
            height: vh(35);
            overflow: hidden;
            position: relative;

            .cutter {
                height: 100%;
                aspect-ratio: 3;
                position: relative;
                overflow: hidden;
                left: -50%;
                translate: 50%;

                img {
                    border-top-left-radius: vh(1);
                    border-top-right-radius: vh(1);
                    position: absolute;
                    width: 100%;
                    object-fit: cover;
                    top: -50%;
                }
            }

            .slide-cover {
                transition: opacity 300ms;
                opacity: 0;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                color: white;
            }
        }

        &:hover {
            .slide-cover {
                opacity: 1;
                transition: opacity 300ms;
            }
        }

        .events-slide-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.7vh 1vw;
            font-size: 1.2vw;
            height: 3.2vh;

            .events-slide-item-right {
                height: inherit;

                span {
                    display: inline-block;
                    vertical-align: middle;
                    height: inherit;
                }

                .event-name {
                    font-weight: bold;
                    margin-left: vh(1);
                    max-width: 13vw;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .ticket-num-desc {
                display: flex;
                align-items: center;
                gap: 0.5vw;

                img {
                    height: 2vh;
                }
            }
        }
    }
}
