@import "./consts.scss";

// pretty much self explained
.the-popup-yes-no {
    background-color: $background-color-primary;
    border-color: black;
    border-radius: vh(1.2);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    width: 40vw;
    height: vh(40);
    text-align: center;

    // this serves as scss for all titles
    .the-title {
        margin: 4vw 5vw;
        text-align: center;
        font: normal normal bold vh(3.7) / vh(4.8) Assistant;
        letter-spacing: 1.08px;
        color: $texts;
        margin-bottom: vh(2.59);
        direction: rtl;
        font-size: 2.3vw;
    }

    .yes-or-no-buttons {
        display: flex;
        justify-content: space-evenly;
        margin-top: 3.5vw;
        margin-right: 3vw;
        margin-left: 3vw;
        width: 100%;
    }
}

.popup-with-img-yes-no {
    background-color: $background-color-primary;
    border-color: black;
    border-radius: vh(1.2);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    width: 55vw;
    height: vh(75);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    .flex-center {
        height: vh(75);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;

        .close-button-text {
            color: $texts;
            font-size: vh(6);
        }

        .photo-popup {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 55vw;
            height: vh(75);

            .the-img {
                margin-bottom: vh(1);
                max-height: vh(64);
                max-width: 40vw;
            }
        }
    }
}

@keyframes tilt {
    25% {
        transform: rotate(15deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-15deg);
    }
}

.thumbs-up-popup {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 40vw;

    .close-button-thumbs-up {
        color: $texts;
        font-size: vh(6);
    }

    .title-and-thumbs-up {
        width: 40vw;

        .the-title-thumbs-up {
            margin: 1vw 5vw;
            text-align: center;
            font: normal normal bold vh(3.7) / vh(4.8) Assistant;
            letter-spacing: 1.08px;
            color: $texts;
            margin-bottom: vh(2.59);
            direction: rtl;
            font-size: 2.3vw;
        }

        .animation-thumbs-up {
            animation: tilt 3s infinite;

            .thumbs-up {
                margin-top: vh(2);
                width: 7vw;
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .the-popup-yes-no {
        width: 80vw;
        height: 30vh;

        .the-title {
            font-size: 3vh;
        }
    }
}