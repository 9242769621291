@import "./consts.scss";

.agency-type-select {
    height: vh(4.5);
    background-color: $inputs-background-color;
    border: vh(0.2) solid $inputs-border;
    direction: rtl;
    box-sizing: border-box;
    text-indent: 7px;
    color: $texts !important;
    font-size: vh(1.8) !important;
    font-family: Assistant sans-serif;
    margin-bottom: vh(1);
    border-radius: 0 !important;
}

.MuiSelect-icon {
    right: unset !important;
    left: 2.5%;
}

.agency-name-select {
    height: vh(4.5);
    background-color: $inputs-background-color;
    display: flex;
    align-items: center;
    text-indent: 7px;
    border: vh(0.2) solid $inputs-border;
    color: $texts;
    font-size: vh(1.8);
    margin-bottom: vh(1);

    .MuiAutocomplete-inputRoot input {
        font-size: vh(1.8);
        padding: unset !important;

        .MuiButtonBase-root {
            font-size: vh(2.4);
        }
    }
}

.agency-type-item {
    font-size: vh(1.8) !important;
    padding: 0.5vh 1vw !important;

    &:focus-visible {
        outline: none;
    }
}

.MuiAutocomplete-listbox {
    @include scrollBar-thin;

    .agency-name-item {
        font-size: vh(1.8) !important;
        padding: 0.5vh 1vw;

        &:hover {
            background-color: #d0d0d047;
        }

        &.selected-option {
            background-color: $inputs-border;
        }
    }
}

.MuiAutocomplete-popupIndicatorOpen {
    transform: rotate(0deg) !important;
}

.MuiAutocomplete-clearIndicator {
    padding: 2px !important;
}

.MuiAutocomplete-endAdornment {
    right: unset !important;
    left: 2% !important;
}

.MuiAutocomplete-inputRoot {
    padding: 2% !important;
}

.MuiAutocomplete-paper {
    margin-right: 0 !important;
}

@media screen and (max-width: $breakpoint) {
    .MuiAutocomplete-root,
    .agency-type-select {
        border-radius: vh(1.7) !important;
    }
}
