@import "../../../../style/consts.scss";

.event-archive-page {
    background-color: $background-color-primary;
    height: 82vh;
    padding-bottom: 7vh;
    overflow: none;

    .event-archive-container {
        margin-right: 10vw;
        color: $texts;
        font-size: 19px;

        .event-archive-title {
            color: $texts;
            font-size: vh(3.5);
            font-weight: 700;
            letter-spacing: 0.5px;
            text-align: right;
            margin-bottom: vh(5);
            padding-top: vh(5);
        }

        .event-archive-items-container {
            height: 64vh;
            overflow: auto scroll;
            @include no-scrollBar;

            .no-results {
                text-align: center;
                font-size: vh(4);
                color: $texts;
                font-weight: 500;
                margin-top: 3vh;
            }
        }

        .search-container {
            width: 85vw;
            display: flex;
            justify-content: right;
            align-items: center;
            margin-bottom: vh(2);

            label {
                //center the image of the magnifying glass
                display: flex;

                .search-icon {
                    position: relative;
                    left: vh(5);
                    width: vh(2.5);
                }
            }

            .event-archive-search {
                border: 1px solid $inputs-border;
                width: vh(25);
                padding-left: vh(6);
                padding-right: vh(3.5);
                height: vh(4.5);
                outline: none;
                background-color: $background-color-primary;
                color: $texts;
                font-size: 19px;
            }

            /* clears the ‘X’ from Internet Explorer */
            input[type="search"]::-ms-clear {
                display: none;
                width: 0;
                height: 0;
            }
            input[type="search"]::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }
            /* clears the ‘X’ from Chrome */
            input[type="search"]::-webkit-search-decoration,
            input[type="search"]::-webkit-search-cancel-button,
            input[type="search"]::-webkit-search-results-button,
            input[type="search"]::-webkit-search-results-decoration {
                display: none;
            }
        }

        .event-archive-items-container {
            .event-archive-item {
                background-color: $event-strip-background;
                height: vh(9.3);
                width: 80vw;
                margin-top: vh(3);
                box-shadow: 0px 3px 6px $box-shadow;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .details-archive {
                    width: 50%;

                    .events-happened-item-right {
                        display: flex;
                        align-items: center;
                        gap: 0.5vh;
                        font-size: vh(2.2);
                        margin-right: 1.5vw;

                        .events-happened-item-name {
                            font-size: vh(2.4);
                            font-weight: 700;
                            margin-left: 0.5vw;
                        }
                    }
                }

                .events-archive-tickets {
                    display: flex;
                    justify-content: flex-start;
                    width: 30%;
                    font-size: vh(2.3);
                    font-weight: 700;
                }

                .events-archive-item-left {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 20%;

                    .event-archive-arrow {
                        width: vh(1.7);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .event-archive-page {
        .event-archive-container {
            margin-right: 0;

            .archive-top {
                margin-right: 10vw;

                .search-container-ios {
                    margin-right: 0vw;
                }
            }

            .event-archive-items-container {
                height: 70vh;

                .event-archive-item {
                    height: vh(16);
                    width: 94vw;
                    margin-right: auto;
                    margin-left: auto;

                    .details-archive {
                        .events-happened-item-name-mobile {
                            font-weight: 700;
                            font-size: vh(2.5);
                        }

                        .events-happened-item-right {
                            width: 130%;
                            margin-right: unset;
                            height: vh(4);
                        }

                        .events-archive-tickets-mobile {
                            font-weight: 700;
                            font-size: vh(2);
                        }
                    }

                    .events-archive-item-left {
                        .event-archive-arrow {
                            width: vh(1.2);
                            margin-left: 1vw;
                        }
                    }
                }

                .event-archive-last-item {
                    min-height: vh(14.2);
                    height: vh(16);
                    width: 94vw;
                    margin-right: -2vw;
                    margin-bottom: vh(3);

                    .details-archive {
                        .events-happened-item-name-mobile {
                            font-weight: 700;
                            font-size: vh(2.5);
                        }

                        .events-happened-item-right {
                            width: 130%;
                            margin-right: unset;
                            height: vh(4);
                        }

                        .events-archive-tickets-mobile {
                            font-weight: 700;
                            font-size: vh(2);
                        }
                    }

                    .events-archive-item-left {
                        .event-archive-arrow {
                            width: vh(1.2);
                            margin-left: 1vw;
                        }
                    }
                }
            }
        }
    }
}
