@import "../../style/consts.scss";

.ticket-method-popup {
    width: 40vw;
    height: 35vh;

    .buttons-container {
        display: flex;
        gap: 2vw;
        margin-top: 4vh;
        margin-bottom: 6vh;

        .ticket-method-btn {
            width: 13vw;
            height: 5vw;
            font-size: vh(2.5);

            .method-desc {
                font-size: vh(2.2);
                font-weight: 600;
            }
        }
    }
}
