@import "../../../../style/consts.scss";

.accessibilities-inputs {
    .accessibility-container {
        display: flex;
        align-items: center;
        gap: 5%;
        margin-bottom: vh(1.5);

        img {
            width: vh(2.5);
        }
    }

    .title {
        margin: vh(1.5) 0 vh(2.5);
    }

    .input-error {
        transition: 0.3s;
        color: $error;
        font-size: vh(1.7);
        text-align: right;
        margin-bottom: vh(1);
        min-height: vh(1);
        font-weight: 400;
    }
}

@media screen and (max-width: $breakpoint) {
    .accessibilities-inputs {

        .input-error {
            height: vh(3);
        }
    }
}