@import "../../../../style/consts.scss";

.feedbacks-agency-page {
    background-color: $background-color-primary;
    height: vh(88);
    padding-top: 1vh;
    overflow: auto scroll;
    @include no-scrollBar;

    .container-title {
        margin: vh(6) vh(20) vh(4.63) 0;
        height: vh(9.6385);
        text-align: right;

        .date {
            text-align: right;
            font: normal normal normal Assistant;
            letter-spacing: 0px;
            color: $texts;
            font-size: vh(3);
        }

        .artist {
            @extend .date;
            font-weight: bold;
            font-size: vh(4);
        }
    }

    .triangle-skeleton {
        position: relative;
        right: 6.37%;
        top: 5vw;
        transform: rotate(45deg);
        background: linear-gradient(to top right, rgba(0, 0, 0, 0) 64%, rgba(0, 0, 0, 0.11) 0.1%, rgba(0, 0, 0, 0.11));
    }

    .container-content-right {
        margin-right: 10vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .feedbacks-container {
        margin-right: 5vw;
        margin-bottom: vh(2);
        border-spacing: 0 4rem;
        max-width: 90%;
    }

    .container-content {
        margin-top: vh(8.34);
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: vh(2);
    }

    .feedbacks-container,
    .container-content {
        .img-in-bubble {
            align-self: center;
            max-height: vh(20);
            max-width: 70vw;
        }

        .feedback-menu {
            position: absolute;
            top: 0;
            left: 0;
        }

        .last-img-in-bubble {
            align-self: center;
            max-height: vh(20);
            max-width: 70vw;
        }
    }

    .loading-chat-row {
        display: flex;
        margin-right: vh(15);
        margin-top: vh(2);
    }

    .parent-container {
        display: flex;

        .icon-container {
            margin: 0 0.75rem -0.75rem -0.75rem;
            min-width: 1.75rem;
            min-height: 1.75rem;
            background-color: $background-color-primary;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: flex-end;

            .icon {
                width: 50%;
                height: 50%;
            }
        }
    }

    .text-on-button {
        display: flex;

        .span {
            display: flex;
            align-items: center;
        }

        .send-icon {
            height: 1.25rem;
            margin-right: 0.8rem;
            margin-bottom: 0;
        }
    }

    .kebab-menu {
        position: absolute;
        top: 0;
        left: 0;
    }

    .feedback-options-text,
    .feedback-options-img {
        width: stretch;
        overflow: hidden;
        white-space: nowrap;
        width: vh(17);
        border-radius: 0px;
        top: 0;
        left: vh(-17);

        .element-text {
            display: flex;
            align-items: center;
            margin-right: vh(1);
            border-radius: 0px;
        }

        .dropdown-element {
            border-radius: 0px;
        }

        svg {
            width: vh(2.5);
            margin-left: vh(0.5);
        }
    }

    .delete-option {
        color: $error;
    }
}

@media screen and (max-width: $breakpoint) {
    .feedbacks-agency-page {
        height: vh(91);

        .container-title {
            width: vh(32.23);
            height: auto;
            margin: vh(2.8) vh(6) vh(2.8) vh(9.2);

            .artist {
                font-size: vh(2.3);
                font-weight: bold;
            }

            .date {
                font-size: vh(2.3);
            }
        }

        .feedbacks-container {
            border-spacing: 0 2rem;
        }

        .container-content {
            .img-in-bubble {
                height: 100%;
                object-fit: contain;
                width: 50vw;
            }
        }

        .feedback-options-text,
        .feedback-options-img {
            transform: translateX(100%) translateY(-100%);
        }

        .loading-chat-row {
            margin-top: vh(5);
            margin-right: 0;
        }

        .circle {
            height: vh(8);
            width: vh(4);
        }

        .round {
            min-width: 0;
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 50%;
            box-shadow: 3px 4px 8px $box-shadow;

            .text-on-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                .send-icon {
                    height: 1.38rem;
                    margin: 0.3rem 0 0 0.3rem;
                }
            }
        }

        .triangle-skeleton {
            right: 9.4%;
        }
    }
}