@import "../../../../style/consts.scss";

.page-not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: vh(70);
    margin-top: vh(3);

    .four-o-four {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        height: vh(30);
        margin-bottom: vh(8);
        .four {
            font-size: vh(30);
            color: rgba(75, 75, 75, 0.904);
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
        }
        .tickets {
            margin-top: vh(8);
            margin-left: 1vw;
            height: vh(22);
        }
    }

    .text {
        font-size: vh(4);
        font-weight: 700;
        color: rgba(75, 75, 75, 0.904);
        font-family: $font;
    }
    .return-button {
        margin-top: vh(4);
        font-size: vh(5);
        font-weight: 600;
        font-family: $font;
        cursor: pointer;
        width: fit-content;
        height: fit-content;
        position: relative;
        z-index: 1;
        &:hover::after {
            width: 100%;
            left: 0;
        }
        &::after {
            content: "";
            position: absolute;
            left: 100%;
            bottom: -5px;
            width: 0%;
            border-bottom: vh(0.5) solid $texts;
            border-radius: 20px;
            transition: all 0.5s cubic-bezier(0.13, -0.13, 0.57, 1.54); // transition: all .2s linear  ;
        }
    }
}

@media screen and(max-width: $breakpoint) {
    .page-not-found-page {
        .four-o-four {
            margin-bottom: unset;
            .four {
                font-size: vh(20);
            }
            .tickets {
                margin-top: vh(5);
                margin-left: 1.5vw;
                height: vh(15);
            }
        }

        .text {
            font-size: vh(4);
            font-weight: 700;
            color: rgba(75, 75, 75, 0.904);
            font-family: $font;
        }
    }
}
