@import "./consts.scss";

.thank-you-row {
    font-size: 1.25rem;
    vertical-align: top;

    .profile-container {
        display: flex;
        flex-direction: column;
        margin-left: 2.5rem;
    }

    .profile-pic {
        align-self: center;
    }

    .content {
        height: fit-content;
        width: fit-content;
        padding: 1.5rem;
        border-radius: 2rem 0 2rem 2rem;
        background-color: $background-color-secondary;
        padding-left: 2vw;
        position: relative;
    }

    .profile-title {
        color: $texts-secondary;
        font-weight: 700;
        font-size: 2.5vh;
        line-height: 3vh;
        text-align: center;
        max-width: 12vh;
        max-height: 6vh;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        hyphens: auto;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

@media screen and (max-width: $breakpoint) {
    .thank-you-row {
        .content {
            padding-left: 7vw;
            padding-right: 3vw;
        }

        .profile-container {
            align-items: center;
            margin-left: 1rem;
            max-width: 15vw;

            .profile-title {
                max-width: 100%;
                width: max-content;
                font-size: 2vh;
            }
        }
    }
}
