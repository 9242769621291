@import "./consts.scss";

.radio-buttons-wrapper {
    .radio-buttons-item {
        margin-bottom: vh(3);
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    label {
        font-size: vh(2);
        width: 85%;
        text-align: right;
    }
    .no-width {
        width: 0;
    }

    input[type="radio"] {
        cursor: pointer;
        appearance: none;
        width: vh(3);
        height: vh(3);
        color: currentColor;
        border: 0.25vh solid currentColor;
        border-radius: 50%;
        display: grid;
        place-content: center;
        margin: 3px 3px 3px 5px;
        background-color: transparent;

        &::before {
            content: "";
            width: 1.2vh;
            height: 1.2vh;
            border-radius: 50%;
            transform: scale(0);
            transition: 80ms transform ease-in-out;
            background-color: currentColor;
        }

        &:checked::before {
            transform: scale(1);
        }

        &:disabled {
            opacity: 0.3;
            cursor: default;
        }
    }
}

.add-new-event {
    width: 100%;
    .radio-buttons-wrapper {
        .radio-buttons-item {
            margin-bottom: calc(var(--vh, 1vh) * 2);
            align-items: flex-start;
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .radio-buttons-wrapper {
        .radio-buttons-item {
            justify-content: space-evenly;
        }
    }
}
