@import "../../../../style/consts.scss";

.event-order-details-container {
    background-color: #00000050;
    width: 100%;
    height: 100%;
    padding: 2.5vh 3vh;
    box-sizing: border-box;
    font-size: vh(2.3);
    font-weight: 500;

    .order-details {
        margin: 2vh 0 2.5vh;
    }

    .order-details-btn {
        font-size: vh(2.3);
        padding: 1vh 2vh;
    }
}
