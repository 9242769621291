@import "../../../../style/consts.scss";

.message-icon {
    width: vh(5);
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiBadge-badge {
        width: vh(2);
        height: vh(2.5);
        font-size: 0.9vw;
        font-family: $font;
    }

    .message {
        width: vh(3.5);
    }
}


@media (max-width: $breakpoint) {
    .message-icon {
        margin-right: -2vw;
        .MuiBadge-badge {
            min-width: vh(0.5);
            height: vh(2);
            font-size: 2.7vw;
        }

        .message {
            width: vh(2.8);
        }
    }

    
}
