@import "../../../../style/consts.scss";

$padding-right: 7vw;

.upcoming-events-page {
    .header {
        position: fixed;
        z-index: 2;
    }

    .agency-home-page {
        height: 70vh;
        padding-right: $padding-right;

        .agency-home-right {
            padding-top: 15vh;

            .home-title {
                width: max-content;
                font-size: vh(4);
                margin-bottom: 4vh;
                margin-right: 1.5vw;
                color: #323232;
            }

            .buttons-container {
                display: flex;
                gap: 1vw;

                .agency-home-btn {
                    width: max-content;
                    padding: 1.6vh 4vh;
                    font-weight: normal;
                }
            }
        }
    }

    .upcoming-events {
        @include system-home-page-section-styles;
        margin-bottom: 3vh;
        background-color: $background-color-secondary;
    }

    .received-feedbacks {
        height: 89vh;
        padding-right: 0;

        .received-feedbacks-right {
            @include system-home-page-section-styles;

            .to-all-feedbacks {
                color: #323232a8;
                font-weight: 500;
                font-size: 2vh;
                cursor: pointer;

                span {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .upcoming-events-page {
        .agency-home-page {
            height: auto;
            padding-right: 0;

            .agency-home-right {
                padding-top: 5vh;
                padding-bottom: 5vh;
                text-align: center;

                .home-title {
                    font-size: vh(2.5);
                    margin: 0 auto 4vh;
                }

                .buttons-container {
                    flex-direction: column;

                    .agency-home-btn {
                        width: 70vw;
                        padding: 1.6vh 4vh;
                        font-size: 2.5vh;
                        font-size: 2.5vh;
                        margin: auto;
                    }
                }
            }
        }

        .upcoming-events {
            .agency-events-swiper {
                margin-right: -7vw;
            }
        }

        .received-feedbacks {
            height: auto;

            .right-side {
                margin-right: 0;
            }

            .feedbacks-bubbles-container {
                width: 82vw;
            }
        }
    }
}
