@import "./consts.scss";

.right-side-in {
    width: 40vw;

    .no-title {
        color: $texts;
        font: normal normal bold 2.083vw/2.708vw Assistant;
        margin-top: vh(7);
        margin-bottom: vh(4);
        font-size: vh(3.5);
    }

    .paragraph-in {
        color: $texts;
        font-size: vh(2.6);
    }

    .wrapper-titles-div {
        align-self: center;
        margin-top: vh(2);
        margin-bottom: vh(4);

        .title-text {
            text-align: right;
            font-size: vh(2.2);
            font-weight: 500;
        }
    }

    .gen-input {
        margin-bottom: vh(2);
    }

    .radio-buttons-not-relevant {
        .incompatible-categories {
            .title {
                padding-top: vh(2);
                font-size: vh(2.2);
                font-weight: 500;
                max-width: 75%;
            }

            .categories-select {
                justify-content: flex-start;
                gap: 1vw;
            }
        }
    }

    .error-empty {
        color: $error;
        font-weight: 400;
        font-size: vh(1.7);
        text-align: right;
        margin-bottom: vh(1);
        height: vh(1);
    }

    .button-in {
        height: vh(6.8);
        margin-top: vh(2.5);
        width: 11vw;
        margin-bottom: vh(5);
        font-size: 3vh;
        float: left;
    }
}

@media screen and (max-width: $breakpoint) {
    .right-side-in {
        background-color: $background-color-primary;
        height: vh(92);
        padding: vh(2);
        padding-bottom: vh(10);
        box-sizing: border-box;
        overflow: scroll;
        width: 100vw;

        .no-title {
            margin-bottom: vh(5);
            font-size: vh(3);
            text-align: center;
            color: $dark-blue;
        }

        .paragraph-in {
            font-weight: 600;
            font-size: vh(2.7);
            text-align: center;
            color: $dark-blue;
        }

        .wrapper-titles-div {
            margin-right: 4vw;

            .title-text {
                font-size: vh(2.4);
            }
        }

        .radio-buttons-not-relevant {
            .incompatible-categories {
                margin-bottom: vh(2);
                margin-right: 3vw;

                .title {
                    font-size: vh(2.4);
                    font-weight: 500;
                }
            }
        }

        .error-empty {
            margin-bottom: vh(2.5);
            margin-right: 12vw;
        }

        .button-in {
            border-radius: vh(4);
            margin-top: vh(0.5);
            margin-right: 62vw;
            height: vh(6);
            width: 24vw;
            font-size: vh(3.5);
        }

        .button-in-IOS {
            border-radius: vh(4);
            margin-top: vh(0.5);
            margin-right: 65.5vw;
            height: vh(6);
            width: 24vw;
            font-size: vh(3.5);
            margin-bottom: vh(3);
        }
    }
}