@import "./consts.scss";

.invisible-input {
    opacity: 0;
    height: 0;
    z-index: -1;
}

.input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
    margin-right: 0;
    max-width: 30vw;
    width: 100%;

    .input-title {
        font-weight: 600;
        color: $texts;
        font-size: vh(2.3);
        margin-top: vh(1);
        margin-bottom: vh(1);
    }

    .secondary-title {
        font-size: vh(1.7);
        color: #41596b9c;
    }

    // remove the arrows from the input number
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .input {
        height: vh(4.5);
        background-color: $inputs-background-color;
        border: vh(0.2) solid $inputs-border;
        border-radius: 0;
        box-sizing: border-box;
        text-indent: 7px;
        color: $texts;
        font-size: vh(1.8);
        font-family: $font;
        width: 30vw;
        align-self: flex-end;

        &:disabled {
            opacity: 0.6;
        }
    }

    .input-price {
        height: vh(4.5);
        background-color: $inputs-background-color;
        border: vh(0.2) solid $inputs-border;
        box-sizing: border-box;
        text-indent: 7px;
        color: $texts;
        font-size: vh(1.8);
        font-family: $font;
        align-self: flex-end;
        width: 100%;
    }

    .input-textarea {
        @extend .input;
        max-width: 100%;
        max-height: vh(14);
        min-height: vh(10);
        min-width: 100%;
        height: 12vh;
        text-indent: unset;
        padding-right: 7px;
    }

    .input:focus {
        outline: transparent;
    }

    .input-price:focus {
        outline: transparent;
    }

    .input.time {
        width: 100%;
    }

    .bottom-text-container {
        display: flex;
        justify-content: space-between;

        .input-error {
            transition: 0.3s;
            color: $error;
            font-size: vh(1.7);
            text-align: right;
            min-height: vh(3);
            font-weight: 400;
        }

        .input-character-limit {
            font-size: 0.6rem;

            &.limit-reached {
                animation: to-red 1s linear;
            }
        }

        @keyframes to-red {
            0% {
                color: $texts;
            }

            20%,
            80% {
                color: $error;
            }

            100% {
                color: $texts;
            }
        }
    }
}

.add-new-event-third-page {
    .input-container {
        .input {
            align-self: self-start;
        }
    }

    &.tickets-limit-text {
        .input-error {
            color: gray;
        }
    }
}

.seatGroup {
    width: 10vw;

    .input-container {
        width: auto;

        .input {
            height: vh(4);
            border: vh(0.15) solid $inputs-border;
            width: 10vw;
            align-self: flex-start;
        }
    }
}

.coupon-input {
    .input-container {
        width: unset;
        min-width: 24vw;

        .input {
            height: vh(4);
            border: vh(0.15) solid $inputs-border;
        }
    }
}

.text-area {
    .input-container {
        display: flex;
        flex-direction: column;
        text-align: right;
        margin-right: 2vw;
        width: unset;
        min-width: 23.5vw;
        max-width: 23.5vw;
    }
}

.terms {
    display: inline-block;

    .input-container {
        display: inline;

        .bottom-text-container {
            display: none;
        }

        .input {
            width: fit-content;
            text-align: center;
            border-top: unset;
            border-right: unset;
            border-left: unset;
            padding-left: 0.5vw;
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .input-container {
        max-width: 85vw;

        .info-mobility {
            .info-icon {
                margin-top: -0.4vh;
                padding-right: 1vw;
            }
        }

        .input-price {
            height: vh(4);
            border-radius: vh(1.7);
            width: 85vw;
        }

        .input-title {
            font-size: vh(2.1);
            font-weight: 600;
        }

        .secondary-title {
            font-size: vh(1.3);
        }

        .input {
            height: vh(4);
            border-radius: vh(1.7);
            width: 85vw;
        }

        .input-textarea {
            min-height: vh(12);
            max-height: vh(12);
            border-radius: vh(2);
        }
    }

    &.tickets-limit-text {
        .input-error {
            color: gray;
        }
    }

    .coupon-input {
        .input-container {
            min-width: 60vw;
        }
    }

    .add-new-event-third-page {
        .input {
            width: 75vw;
        }
    }

    .seatGroup {
        width: 60vw;

        .input-container {
            min-width: 25vw;
            margin-left: 4vw;

            .input {
                width: 30vw;
            }
        }
    }

    .text-area {
        .input-container {
            width: 70vw;
            min-width: unset;
            max-width: unset;
            margin-right: 10vw;

            .input {
                height: vh(4);
                border: vh(0.15) solid $inputs-border;
            }
        }
    }

    .terms {
        .input-container {
            .input {
                padding-left: 1.5vw;
            }
        }
    }
}
