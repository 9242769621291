@import "./consts.scss";

.stepper-circle {
    position: relative;
    background-color: $background-color-left;
    border: 1.5px solid $texts;
    height: vh(3);
    width: vh(3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.colored {
        background-color: $btn-blue;
    }
    .stepper-checkmark {
        height: vh(1.7);
        margin-bottom: vh(0.3);
    }
}
.MuiStepper-root {
    width: 100% !important;
    margin-left: 25% !important;
    margin-bottom: 4vh !important;
}
.MuiStep-root {
    padding: 0 !important;
}

.MuiStepLabel-iconContainer {
    padding: 0 !important;
}
