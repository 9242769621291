@import "./consts.scss";

.checkbox-item {
    display: flex;
    justify-content: right;
    align-items: center;
}

.checkbox-title {
    font-size: vh(2.3);
    margin-right: vh(1);
    display: flex;
    align-items: center;
    text-align: start;
    font-weight: 300;
    cursor: pointer;
}

.category-checkbox {
    margin-right: vh(2);
    display: flex;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    height: vh(3);
    width: vh(3);
    color: currentColor;
    border: 0.25vh solid $check-box-radio;
    cursor: pointer;

    display: grid;
    place-content: center;

    &::before {
        content: "";
        width: 1.2vh;
        height: calc(1.2vh / 2);

        border: 0.25vh solid currentColor;
        border-top: none;
        border-right: none;

        transform-origin: bottom left;
        rotate: -45deg;
        translate: 33% 33%;
        transform: scale(0);
        transition: 80ms transform ease-in-out;
    }

    &:checked::before {
        transform: scale(1);
    }
}

.order-seat-groups {
    .the-checkbox {
        border-color: $dark-blue;
        border-radius: 3px;
    }
}

.terms {
    .the-checkbox {
        align-self: flex-start;
        margin-top: vh(1.5);
    }
}

.question-checkbox {
    margin-bottom: vh(3.5);

    .checkbox-item {
        .the-checkbox {
            min-width: vh(3);
        }
    }
}

@media screen and (max-width: $breakpoint) {
    input[type="checkbox"] {
        margin-left: 1.5vw;
    }

    .order-seat-groups {
        margin-right: 10%;
    }
}
