@import "./consts.scss";

.hidden {
    visibility: hidden;
}

.dropdown-container {
    //the dropdown container that holds the dropdown elements
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    background: $header;
    box-shadow: 0px 3px 6px $box-shadow;
    border-radius: 8px;
    z-index: 10; // make sure that the dropdown is always on top
    height: fit-content;
    width: fit-content;
    background-color: #d1d1d1;
    left: 3%;
    top: 9vh;

    .dropdown-element {
        //each element in the dropdown
        height: fit-content;
        min-width: 8vw;
        padding-top: vh(0.5);
        color: $btn-cyan;
        padding: 1.5vh 2vh;
        margin-bottom: 1px;
        background-color: $header;
        text-align: right;
        font: normal normal normal 1.853vh/2.409vh Assistant;

        .element-text {
            display: inline-block;
        }

        &:hover {
            cursor: pointer;
        }

        .messages-count-badge {
            background-color: $yellow;
            color: $texts;
            display: inline-block;
            border-radius: 50%;
            width: 1.2vw;
            height: 1.2vw;
            line-height: 1.2vw;
            text-align: center;
            margin-right: 7%;
        }
    }

    .dropdown-element:last-child {
        border-radius: 0 0 1vh 1vh;
    }
}

// phone screen
@media screen and (max-width: $breakpoint) {
    //responsive designe for phones
    .dropdown-container {
        width: 43.889vw;

        .dropdown-element {
            font: normal normal normal 3.8vw Assistant;
            font-weight: 500;

            .agency {
                margin-right: 14%;
            }

            .messages-count-badge {
                font-size: 3.5vw;
                width: 5vw;
                height: 5vw;
                line-height: 5vw;
            }
        }
    }
}
