@import "./consts.scss";

.search-container {
    display: flex;
    align-items: center;
    position: relative;
    overflow: auto hidden;
    margin-top: vh(2);

    label {
        //center the image of the magnifying glass
        display: flex;

        .search-icon {
            position: relative;
            left: vh(5);
            width: vh(2.5);
        }
    }

    .input-search {
        border: 0.5px solid $texts;
        width: vh(36);
        padding-left: vh(6);
        padding-right: vh(2);
        height: vh(4);
        outline: none;
        background-color: $background-color-primary;
        color: $texts;
        font-size: vh(2.2);
    }
}
@media (max-width: $breakpoint) {
    .search-container {
        margin-right: 5vw;
        .input-search {
            width: 45vw;
            height: 3.5vh;
            font-size: vh(1.5);
        }
        label {
            .search-icon {
                width: vh(1.8);
            }
        }
    }
}
