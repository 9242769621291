@import "../../../../style/consts.scss";

.coupon-seat-group {
    .seatgroup-number-title {
        font-size: vh(2);
        font-weight: 600;
    }

    .seatgroup-ticket-num {
        margin-bottom: vh(-2);

        .input {
            width: 10vw;
            align-self: flex-start;
        }
    }
}
