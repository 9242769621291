@import "../../../style/consts.scss";

@keyframes bigger {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

.footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    background-color: $footer-background;
    height: vh(11);
    box-shadow: 0px -3px 6px $box-shadow;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding: 0 3vw;

    .left-buttons-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: vh(4);

        .button-in-footer {
            height: vh(6.5);
            border-radius: vh(20);
            font-weight: 700;
            color: $texts;
            font-size: vh(2.5);

            .items-in-button {
                display: flex;
                align-items: center;
                gap: 1vw;
                width: fit-content;

                .action-icon {
                    height: vh(2);
                }
            }
        }

        .flashing-button {
            animation: bigger;
            animation-duration: 3s;
        }

        .blue-button {
            background-color: $btn-blue;

            &.special-button {
                background-color: transparent;
                box-shadow: none;

                &:hover {
                    background-color: #b8c8cc;
                    background-color: $btn-blue;
                    box-shadow: 0px 3px 6px $box-shadow;
                }
            }
        }

        .orange-button {
            background-color: $btn-yellow;

            .items-in-button {
                .action-icon {
                    height: vh(2.5);
                }
            }
        }
    }

    .right-text {
        font-weight: 700;
        font-size: vh(3);
        color: $dark-gray;
        letter-spacing: 0.6px;
    }

    .right-buttons-container {
        display: flex;
        gap: 1vw;

        .icon-in-footer {
            display: flex;
            border-radius: vh(10);
            padding: vh(1);
        }

        .icon-in-footer:hover {
            background-color: $background-color-primary;
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .footer {
        flex-direction: column-reverse;
        gap: 2vh;
        padding: 2vh 4vw 3vh;
        height: auto;

        .left-buttons-container {
            gap: 3vw;

            .blue-button,
            .orange-button {
                width: auto;
                flex: 1;
                padding: 1vh 3vh;

                .items-in-button {
                    width: max-content;
                    gap: 2vw;
                }
            }
        }

        .time-slider {
            align-self: flex-start;
        }
    }
    &.edit-footer{
        flex-direction: row-reverse;
    }
}