@import "../../../../style/consts.scss";

.home {
    width: 100vw;
    height: vh(100);

    .home-page {
        background-color: $event-strip-background;
        color: $dark-blue;
        flex-direction: column;
        align-items: center;
        display: flex;
        padding-top: vh(12);
        font-family: $font;
        width: 100vw;

        .home-page-logo {
            margin-bottom: vh(3);
            height: vh(15);
        }

        .home-page-title {
            letter-spacing: vh(0.2);
            margin-bottom: vh(7);
            font-size: vh(3.7);
            font-weight: 600;

            .home-page-semi-title {
                margin-left: 0.4vw;
            }
        }

        .statistics-container {
            display: flex;
            gap: 8vw;
        }

        .statistic-row-right {
            justify-content: center;
            align-items: flex-end;
            display: flex;
            gap: 8vw;

            .statistics-box {
                flex-direction: column;
                align-items: center;
                display: flex;

                .stat-img {
                    margin-bottom: vh(3);
                    height: vh(9);
                }

                .number {
                    margin-bottom: vh(0.5);
                    font-size: vh(4.5);
                    font-weight: 700;
                }

                .title {
                    font-size: vh(3);
                }
            }
        }

        .statiscs-row {
            margin-top: vh(13);
            gap: 8vw;
            justify-content: space-evenly;
            flex-direction: row;
            display: flex;

            .statistics-box {
                flex-direction: column;
                align-items: center;
                display: flex;

                .stat-img {
                    margin-bottom: vh(3);
                    height: vh(9);
                }

                .number {
                    margin-bottom: vh(0.5);
                    font-size: vh(4.5);
                    font-weight: 700;
                }

                .title {
                    font-size: vh(3);
                }
            }
        }

        .paragraph {
            align-self: flex-start;
            margin-bottom: vh(9);
            padding-right: 10vw;
            margin-top: vh(7);

            .paragraph-title {
                margin-bottom: vh(2);
                font-size: vh(3.5);
            }

            .paragrph-row {
                margin-bottom: vh(1);
                font-size: vh(2.8);
            }
        }

        .partners-container {
            background-color: white;
            display: flex;
            width: 100vw;

            .partners {
                justify-content: space-evenly;
                align-items: center;
                display: flex;
                background-color: white;
                padding-right: 4vw;
                margin-left: -4vw;
                width: 50%;
                gap: 1vw;

                .partners-img {
                    padding-top: vh(2);
                    padding-bottom: vh(2);
                    height: vh(8);
                }

                .small-img {
                    height: vh(5);
                }

                .big-img {
                    height: vh(8);
                }
            }

            .partners-second-column {
                background-color: white;
                justify-content: space-evenly;
                align-items: center;
                display: flex;
                padding-left: 5vw;
                width: 50%;
                gap: 4vw;

                .partners-img {
                    height: vh(6);
                }

                .big-img {
                    height: vh(4);
                }
            }
        }

        .home-page-bold {
            margin-right: 0.25vw;
            margin-left: 0.25vw;
            font-weight: bold;
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .home {
        .home-page {
            background-color: white;

            .home-page-logo {
                height: vh(8);
            }

            .home-page-title {
                font-size: vh(2.4);
            }

            .statistics-container {
                margin-right: 0vw;
                display: flex;
                padding-top: 11vh;
                padding-bottom: 2vh;
                gap: 1.4vh;
                width: 82vw;
                height: 46vh;

                .statistic-row-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 1.4vh;
                    width: 50%;

                    .bigger-container {
                        &.statistics-box {
                            background-color: $event-strip-background;
                            border-radius: 5px;
                            width: 100%;
                            height: 50%;

                            .stat-img {
                                height: 5.3vh;
                                margin-top: 5vh;
                                margin-bottom: 3.5vh;
                            }

                            .number {
                                font-size: vh(2.7);
                            }

                            .title {
                                font-size: vh(1.7);
                                margin-bottom: 3vh;
                            }
                        }
                    }

                    .statistics-box {
                        background-color: $event-strip-background;
                        border-radius: 5px;
                        width: 100%;
                        height: 40%;

                        .stat-img {
                            margin-bottom: vh(1);
                            margin-top: 3.4vh;
                            height: 5.3vh;
                        }

                        .number {
                            margin-bottom: vh(0.2);
                            font-size: vh(2.7);
                            margin-top: 1vh;
                        }

                        .title {
                            font-size: vh(1.7);
                            margin-bottom: 3vh;
                            font-weight: 500;
                        }
                    }
                }

                .statiscs-row {
                    margin-top: 0vh;
                    gap: 1.4vh;
                    flex-direction: column;
                    justify-content: flex-start;
                    width: 50%;

                    .bigger-container {
                        &.statistics-box {
                            background-color: $event-strip-background;
                            border-radius: 5px;
                            width: 100%;
                            height: 50%;

                            .stat-img {
                                height: 5.3vh;
                                margin-top: 5vh;
                                margin-bottom: 3.5vh;
                            }

                            .number {
                                font-size: vh(2.7);
                            }

                            .title {
                                font-size: vh(1.7);
                                margin-bottom: 3vh;
                            }
                        }
                    }

                    .statistics-box {
                        background-color: $event-strip-background;
                        border-radius: 5px;
                        width: 100%;
                        height: 40%;

                        .stat-img {
                            margin-bottom: vh(1);
                            margin-top: 3.4vh;
                            height: 5.3vh;
                        }

                        .number {
                            margin-bottom: vh(0.2);
                            font-size: vh(2.7);
                            margin-top: 1vh;
                        }

                        .title {
                            font-size: vh(1.7);
                            margin-bottom: 3vh;
                            font-weight: 500;
                        }
                    }
                }
            }

            .paragraph {
                margin-top: 7vh;

                .paragrph-row {
                    font-size: vh(2.5);
                    width: 82vw;
                }
            }

            .partners-container {
                background-color: $event-strip-background;
                flex-direction: column;
                display: flex;

                .partners {
                    background-color: $event-strip-background;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    padding-top: 1.4vh;
                    padding-right: 0;
                    margin-left: 0;
                    height: 7vh;
                    width: 100vw;
                    gap: 7vw;

                    .partners-img {
                        height: vh(6);
                    }

                    .small-img {
                        height: vh(4.2);
                    }

                    .big-img {
                        height: vh(2);
                    }
                }

                .partners-second-column {
                    background-color: $event-strip-background;
                    align-items: center;
                    display: flex;
                    padding-bottom: 1.4vh;
                    padding-top: 0.2vh;
                    padding-left: 0;
                    width: 100vw;
                    height: 7vh;
                    gap: 0;

                    .partners-img {
                        height: vh(5.2);
                    }

                    .big-img {
                        height: vh(3.3);
                    }
                }
            }

            .home-page-bold {
                margin-right: 0.95vw;
                margin-left: 0.95vw;
                font-weight: bold;
            }
        }
    }
}
