@import "./consts.scss";

.future-events {
    @include titles;

    &.future-events-home-page {
        @include system-home-page-section-styles;
    }

    .no-events {
        font-size: vh(4);
        font-weight: 600;
        text-align: -webkit-center;
        margin-top: 7vh;
    }

    .info-about-all-events {
        display: flex;
        width: 85vw;
        justify-content: space-between;
        align-items: center;

        .items-in-button {
            display: flex;
            width: fit-content;
            gap: vh(1);
        }
    }

    .ordered-events-list {
        display: flex;
        width: 85vw;
        justify-content: flex-start;
        flex-wrap: wrap;
        column-gap: 3vw;
        row-gap: 3vw;
        padding-bottom: 5vh;
    }

    .event-details-card {
        background-color: #f5f7f8;
        gap: 1vh;
    }
}

.skeleton-home-page {
    margin-top: 3vh;
    padding-right: 7vw;
    background-color: white;
}

.skeleton-events-page {
    background-color: none;
    display: flex;
    flex-direction: column;
    row-gap: 4vh;

    .skeleton-title {
        transform: none;
        width: 35vh;
        height: 5vh;
    }

    .skeleton-cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 85vw;
        gap: 4vh;

        .skeleton-card {
            transform: scale(1, .90);
            transform: none;
            width: 18vw;
            height: 38vh;
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .items-in-button {
        width: 48vw;
    }

    .future-events {
        .no-events {
            font-size: vh(2.5);
            margin-right: -7vw;
        }

        .swiper-container {
            margin-right: -7vw;
        }

        &.future-events-home-page {
            padding-bottom: 8vh;
        }

        .ordered-events-list {
            gap: 3.5vh;
            margin: auto;
        }
    }

    .skeleton-events-page .skeleton-cards-container .skeleton-card {
        width: 100%;
    }
}