@import "./consts.scss";

.error-modal {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .error-container {
        background-color: $error;
        border-radius: 15px;
        width: fit-content;
        height: fit-content;
        min-height: 2vh;
        color: white;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        margin-bottom: vh(5);
        padding: 0 20px;

        .error-text {
            font-size: vh(2.2);
            font-weight: 600;
        }

        .MuiIconButton-root {
            padding-left: 0;
        }
    }

    // hide the black background behind the modal using important style on the MUI
    .MuiModal-backdrop {
        opacity: 0 !important;
    }
}
