@import "./consts.scss";

.no-new-events-text {
    font-weight: normal;
    font-size: vh(3);
    margin-top: vh(-3);
    margin-bottom: vh(6);
}

@media screen and (max-width: $breakpoint) {
    .no-new-events-text {
        font-weight: normal;
        font-size: vh(2.8);
        margin-top: vh(-4);
        margin-bottom: vh(6);
    }
}
