@import "../../../../style/consts.scss";

.add-event-right-first {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5vh;

    .add-event-errors-first {
        color: $error;
        font-size: vh(1.7);
        margin-bottom: vh(1);
        min-height: vh(1);
    }

    .margin-top {
        margin-top: 3%;
    }

    .input-container {
        .mui-input {
            display: flex;
            justify-content: center;
            margin-top: unset;
        }

        input.MuiOutlinedInput-input.MuiInputBase-input {
            font-size: vh(1.8);
            font-family: $font;
            color: $texts;
        }

        .select-mui {
            // font of value in select
            font-size: vh(2.03);
            font-family: $font;
            color: $texts; // settings for font size color and family with spesific class of material-ui
            border-radius: 0;
        }

        .event-time-notice {
            margin-bottom: 1vh;
        }
    }

    .add-event-right-checkboxes-first {
        width: 100%;
    }

    .add-event-yellow-button-first {
        height: vh(7);
        width: 13vw;
        font-size: vh(3);
        align-self: flex-end;
    }

    .add-event-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: vh(4);

        .add-event-yellow-button-second {
            height: vh(7);
            width: 11vw;
            margin-right: 2vw;
        }

        .add-event-other-button-second {
            height: vh(7);
            text-decoration: underline;
            border: 0;
            font-family: $font;
            font-size: 1.5vw;
            font-weight: 700;
            text-align: center;
            color: $texts;
            background-color: $background-color-primary;
            cursor: pointer;
        }
    }
}

li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
    //override for dropdown menu
    font-size: vh(2.03);
    color: $texts; // settings for font size color and family with specific class of material-ui
    font-family: $font;
    min-height: auto;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
    color: $texts;
    font-family: $font;
}

.hall-info {
    text-align: right;
    background-color: $inputs-background-color;
    border: vh(0.2) solid $inputs-border;
    width: 26vw;
    padding: 2vw;
    color: $texts;
    font: normal normal 600 20px/26px Assistant;
}

.MuiPickersArrowSwitcher-root {
    flex-direction: row-reverse;
}

@media screen and (max-width: $breakpoint) {
    .add-event-right-first {
        width: 85vw;

        .hall-info {
            width: 80vw;
            margin-bottom: vh(3);
        }

        .MuiIconButton-root {
            margin-top: 0;
        }

        .input-container {
            .select-mui {
                border-radius: vh(1.7);
            }

            .event-time-notice {
                width: 84vw;
            }
        }

        .add-event-yellow-button-first {
            font-size: 6vw;
            min-width: 30vw;
            border-radius: vh(4);
            margin-bottom: vh(15);
        }

        .add-event-buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .add-event-yellow-button-second {
                margin-left: -20vw;
                margin-right: 3vw;
                font-size: 5vw;
                min-width: 30vw;
                border-radius: vh(4);
                margin-bottom: vh(11);
            }

            .add-event-other-button-second {
                font-size: 5vw;
                min-width: 30vw;
                border-radius: vh(4);
                margin-bottom: vh(11);
            }
        }
    }

    .add-event-input-title {
        font-size: vh(2.2);
        font-weight: 600;
        margin-bottom: vh(1);
    }

    .MuiTimePickerToolbar-hourMinuteLabel {
        direction: ltr;
    }

    .add-event-right-checkboxes-first-ios {
        width: 100%;
        display: flex;
        flex-direction: column;

        .add-event-input-title {
            font-size: vh(2.2);
            font-weight: 600;
            margin-bottom: vh(1);
        }
    }
}