@import "./consts.scss";

.trash-can-container {
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 2.5vh;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 0.2vh;

    &:hover,
    &:focus {
        .top {
            transform: translateX(-3px) rotate(-30deg) translateY(-3px);
        }
    }
}

div:has(.trash-can-container) {
    position: relative;
}
