@import "../../style/consts.scss";

.crop-popup {
    width: 80%;
    position: relative;
    .crop-container {
        position: relative;
        height: 40vh;
        margin: auto;
    }

    .crop-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2.5vh auto;

        .slider {
            width: 50%;

            .rail {
                color: $popup-background;
                height: 2px;
            }

            .track {
                color: $btn-blue;
                border-width: 0.4px;
                height: 2px;
            }

            .thumb {
                color: $btn-blue;
                width: 2.5vh;
                height: 2.5vh;
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .crop-controls {
        gap: 10%;
    }
}
