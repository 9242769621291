@import "./consts.scss";

.loading-circles-container {
    width: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@mixin circle {
    width: 20%;
    height: 1vw;
    border-radius: 50%;
    background-color: white;
    animation-name: jump;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-direction: alternate;
}

@keyframes jump {
    from {
        transform: translateY(25%);
    }
    to {
        transform: translateY(-25%);
    }
}

.circle-1 {
    @include circle;
}
.circle-2 {
    @include circle;
    animation-delay: 0.4s;
}
.circle-3 {
    @include circle;
    animation-delay: 0.8s;
}

@media (max-width: $breakpoint) {
    .loading-circles-container {
        width: 12.5vw;

        .circle-1,
        .circle-2,
        .circle-3 {
            height: 2.5vw;
        }
    }
}
