@import "./consts.scss";

//right side
.signup-right {
    width: 50vw;
    display: flex;
    flex-direction: column;

    .signup-right-input-container {
        text-align: right;
        max-width: 30vw;

        .signup-right-title {
            font-weight: 700;
            font-size: vh(4);
            margin-top: vh(5);
            margin-bottom: vh(1);
        }

        .signup-inputs {
            .organization-type-select {
                height: vh(4.5);
                background-color: $inputs-background-color;
                border: vh(0.2) solid $inputs-border;
                box-sizing: border-box;
                text-indent: 7px;
                color: $texts;
                font-size: vh(1.8);
                margin-bottom: vh(1);
                border-radius: 0;

                .MuiSelect-icon {
                    right: unset;
                    left: 3.5%;
                }
            }

            .signup-all-other-inputs-container {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 100%;
                margin-bottom: 4vh;

                .other-inputs-titles {
                    font-weight: 600;
                    font-size: vh(2.2);
                    margin-bottom: vh(1);
                }

                .other-inputs {
                    display: flex;
                    flex-direction: column;
                    background-color: $inputs-background-color;
                    position: relative;
                    overflow: hidden;
                    text-align: center;
                    border: vh(0.2) solid $inputs-border;

                    justify-content: flex-end;

                    .signup-text-file {
                        .org-signup-files {
                            display: flex;
                            align-items: center;
                            position: relative;
                            overflow: hidden;
                            margin-right: 0.5vw;
                            margin-top: auto;
                            margin-bottom: auto;
                            font-size: vh(2);
                            height: vh(4);
                            align-content: center;

                            .upload-arrow-image {
                                width: 1.5vw;
                                margin-left: 0.5vw;
                            }
                        }
                    }

                    .signup-input-date {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        height: vh(4);
                        align-content: center;

                        .mui-input {
                            // special inputs from material-ui
                            width: 100%;
                            background-color: unset;

                            input.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedEnd {
                                color: $texts; // settings for font size color and family with spesific class of material-ui
                                font-size: vh(2.2);
                                font-family: "Assistant";
                                direction: rtl;
                            }

                            @media screen and (max-width: $breakpoint) {
                                input.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedEnd {
                                    margin-bottom: vh(0.5);
                                    font-size: vh(1.8);
                                }
                            }

                            input.MuiOutlinedInput-input.MuiInputBase-input {
                                @extend input.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedEnd; // for responsive design
                            }

                            .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
                                color: black;
                            }

                            .MuiSvgIcon-root {
                                font-size: 1rem;
                            }
                        }

                        .MuiFormControl-root.MuiTextField-root.input.mui-input {
                            display: flex;
                            justify-content: center;
                            height: vh(5.45);
                        }

                        .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
                            display: flex;
                            flex-direction: row-reverse;
                            padding-right: 6px;
                        }
                    }

                    .input-file-text {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        cursor: pointer;
                        opacity: 0;
                    }

                    input#file-upload-botton {
                        cursor: pointer;
                    }
                }

                .limit {
                    color: $texts;
                    font-weight: 400;
                    font-size: vh(1.5);
                    text-align: right;
                    margin-bottom: vh(1);
                    height: vh(1);
                }
            }
        }

        .signup-right-button {
            height: vh(6.5);
            width: 10vw;
            margin-right: 20vw;
            margin-top: vh(3);
            margin-bottom: vh(12);
        }
    }
}

.MuiPickersArrowSwitcher-root {
    flex-direction: row-reverse;
}

@media screen and (max-width: $breakpoint) {
    .signup-right {
        width: 100vw;

        .signup-right-input-container {
            max-width: 85%;
            margin: 0 auto;

            .signup-right-title {
                margin-top: vh(3);
                font-size: vh(3.5);
            }

            .signup-logo-title {
                font-size: vh(2.2);
            }

            .signup-inputs {
                .organization-type-select {
                    border-radius: 1.7vh;
                }

                .signup-all-other-inputs-container {
                    flex-direction: column;
                    width: 100%;

                    .other-inputs-titles {
                        font-size: vh(2.2);
                    }

                    .other-inputs {
                        border-radius: vh(2);
                        height: vh(4.5);

                        .signup-text-file {
                            margin-right: 2%;

                            .org-signup-files {
                                margin-top: vh(0.7);
                                font-size: vh(1.8);

                                .upload-arrow-image {
                                    width: 3.5vw;
                                    margin-left: 1vw;
                                    margin-right: 1vw;
                                }
                            }
                        }
                    }
                }
            }

            .signup-right-button {
                float: left;
                margin-bottom: vh(13);
                border-radius: vh(20);
                font-size: vh(3.3);
                width: fit-content;
                padding: 0 3vh 0 3vh;
            }
        }
    }
}
