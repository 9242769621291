@import "../../../style/consts.scss";

.header {
    position: sticky;
    top: 0;
    z-index: 5;
    height: $header-height;
    width: 100vw;
    background-color: $btn-cyan;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dark-blue;
    padding-right: 2.5vw;
    padding-left: 3.5vw;
    box-sizing: border-box;

    .website-name-container {
        display: flex;

        .website-name {
            height: vh(5);
            cursor: pointer;
        }
    }

    .header-content {
        display: flex;
        align-items: center;
        gap: 2vw;
        color: white;
        font-size: vh(2.5);
        font-weight: 600;
        white-space: nowrap;

        .contact-us-title {
            min-width: 0;
            cursor: pointer;
        }

        .user-info {
            display: flex;
            justify-content: space-between;
            gap: 2vw;
            align-items: center;

            .avatar-logo {
                cursor: pointer;

                .MuiBadge-badge {
                    min-width: 2px;
                    font-size: 0.75rem;
                    font-family: $font;
                    line-height: 2.5vh;
                    width: 2.5vh;
                    height: 2.5vh;
                    border-radius: 50%;
                }
            }
        }

        .side-nav {
            .sidebar-list {
                color: white;

                width: 100vw;
                height: 100vh;
                background-color: $btn-cyan;

                .exit-btn-div {
                    margin-right: 6.4vw;
                    margin-top: 2.96vh;
                }

                .nav-labels-container {
                    width: 100vw;

                    .label-container {
                        display: flex;
                        color: white;
                        margin-top: 8vh;
                        font-weight: 600;
                        font-size: 2.25vh;
                        margin-right: 11vw;

                        .label-name {
                            height: 4vh;
                            width: 10vw;
                        }

                        .side-nav-text {
                            margin-right: 5vw;
                            display: flex;
                            align-items: center;
                        }
                    }

                    .logout {
                        display: flex;
                        color: white;
                        font-weight: 600;
                        font-size: 2.25vh;
                        position: absolute;
                        bottom: 2%;
                        margin-right: 11vw;
                        margin-bottom: 2vh;

                        .logout-div {
                            margin-right: 5vw;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .header {
        height: $header-height-mobile;

        .website-name-container {
            .website-name {
                height: 3vh;
            }
        }

        .avatar-logo {
            .profile-pic-wrapper {
                height: 3.7vh;
                width: 3.7vh;
            }
        }
    }
}