@import "../../../../style/consts.scss";

.third-card {
    background-color: $background-color-primary;
    box-shadow: 3px 4px 8px $box-shadow;
    box-shadow: 1px 4px 7px 3px $box-shadow;

    .main {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .link {
        color: #0C414F;
        color: $texts-secondary;
        font-weight: 600;
    }
}