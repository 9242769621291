@import "./consts.scss";

$gap: 2vh;

.left-side-layout {
    width: 100%;
    height: 100%;
    display: flex;
    gap: $gap;
    padding: $gap;
    background-color: $header;
    box-sizing: border-box;

    .squares-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: $gap;

        .square {
            background-color: $event-strip-background;
            border-radius: 1vh;
            flex: 1;
            color: $texts;
            font-size: vh(2.2);
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2vh;
            padding: 1vw;

            img {
                width: 37%;
            }

            .big-text {
                font-weight: bold;
                font-size: vh(4);
            }

            .gray-text {
                color: #787878;
            }
        }
    }
}

@media (max-width: $breakpoint) {
    .left-side-layout {
        height: 60%;

        .squares-container {
            height: 50vh;

            .square {
                gap: 1.8vh;
            }

            .square img {
                width: 35%;
            }
        }
    }
}