@import "./consts.scss";

$blue-background: #1ececb;

.generic-button.MuiButtonBase-root {
    border-radius: 3.698vw;
    border: 0;
    font-family: $font;
    font-size: 1.5vw;
    font-weight: 700;
    min-width: vh(15);
    text-align: center;
    box-shadow: 0px 3px 6px $box-shadow;
    color: $texts;
    outline: none;
    margin: unset;
    width: fit-content;

    .text-on button,
    .hidden {
        display: none;
    }

    &.disabled {
        filter: brightness(70%);
        background-color: $background-color-primary;
        color: $lighter-gray;
    }

    &:hover {
        cursor: pointer;
        filter: brightness(110%);
    }

    .MuiTouchRipple-root * {
        color: $header;
    }

    &.cyan {
        background-color: $btn-cyan;
        color: white;
    }

    &.border-cyan {
        border: 2px solid $btn-cyan;
        color: $btn-cyan;
        background-color: white;
    }

    &.yellow {
        background-color: $btn-yellow;
    }

    &.blue {
        background-color: $btn-blue;
    }

    &.red {
        background-color: #fb4a59;
    }

    &.turquise {
        background-color: $btn-blue;
    }

    &.left {
        display: block;
        margin-right: auto;
    }

    &.home-button {
        min-width: vh(24);
        height: vh(8);
        font-size: vh(3.5);
        background-color: $btn-yellow;
        color: $dark-blue;

        &:hover {
            background-color: #c0851f;
        }
    }

    &.big {
        width: 22.5vw;
        height: vh(8);
        font-size: 1.823vw;
    }

    &.medium {
        width: 15vw;
        height: vh(7.5);
        font-size: 1.823vw;
    }

    &.small {
        width: 14vw;
        padding: 0 1.5vw;
        height: vh(7);
        font-size: 1.823vw;
    }

    &.extra-small {
        width: fit-content;
        padding: 0 1.5vw;
        height: vh(5);
        font-size: 1.823vw;
    }

    &.thank-you {
        width: 15vw;
        height: vh(5);
        position: relative;
    }

    &.edit {
        width: 12vw;
        height: vh(6);
        font-size: 1.5vw;
        margin-right: 28vw;
        margin-bottom: vh(2);
    }

    &.delete {
        width: 11.7vw;
        height: vh(7);
        font-size: 1.823vw;
    }

    &.review-event {
        height: vh(6.5);
        padding: 0 3vh 0 3vh;
        width: 11vw;
        margin-top: vh(1.5);
        margin-bottom: vh(4);
    }

    &.seat-group {
        background-color: $btn-blue;
        font-size: vh(2.7);
        padding: 4px 10px 4px 10px;
    }

    &.back-button {
        padding: 0 7vw 0 7vw;
    }

    &.category-pop-up {
        margin-top: 2vh;
    }

    &.add-hall-btn {
        height: vh(4);
        padding: 2% 5%;
        font-size: vh(2);
    }

    &.fixed {
        position: fixed;
        top: vh(87);
        left: 3vw;
    }

    &.show-events {
        font-size: vh(2.2);
        font-weight: 600;
        border: 1.8px solid $texts;
        height: vh(5);
        padding: 1vw;
    }

    .loading-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 770px) {
        //responsive designe for phones

        &.big {
            width: vh(19);
            height: vh(4.8);
            font-size: vh(2.5);
            border-radius: vh(4);
        }

        &.small {
            border-radius: vh(4);
            font-size: vh(2.3);
            width: 40vw;
        }

        &.extra-small {
            width: 28vw;
        }

        &.thank-you {
            width: 40vw;
            height: 8vw;
            font-size: 4vw;
            border-radius: vh(4);
        }

        &.thank-you-pressed {
            width: 40vw;
            height: 8vw;
            border-radius: vh(4);
            padding-right: unset;
        }

        &.review-event {
            float: left;
            margin-bottom: vh(7);
            border-radius: vh(20);
            font-size: vh(3);
            width: max-content;
            padding: 0 3vh 0 3vh;
        }

        &.edit {
            width: 25vw;
            height: vh(6);
            border-radius: vh(15);
            font-size: 3.5vw;
        }

        &.show-events {
            font-size: vh(1.8);
            font-weight: 600;
            border: 1.8px solid $texts;
            height: vh(4);
            float: left;
            margin-left: 5vw;
            margin-top: vh(2)
        }

        &.delete {
            width: 20vw;
            font-size: 3.5vw;
            border-radius: vh(4);
        }

        &.seat-group {
            margin-right: vh(-5);
            font-size: vh(2);
        }

        &.category-pop-up {
            margin-top: 3vh;
        }
    }

    @media screen and (max-width: 460px) {
        &.edit {
            font-size: 4vw;
        }

        &.delete {
            width: 10vw;
            height: vh(6);
            font-size: 5vw;
        }

        @media screen and (max-width: 400px) {
            .generic-button {
                &.delete {
                    min-width: 27vw;
                    font-size: 5vw;
                }
            }
        }
    }

    @media screen and (max-width: 350px) {
        .generic-button {
            &.edit {
                font-size: 4.5vw;
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .generic-button.MuiButtonBase-root {
        border-radius: 10vh;
    }
}