@import "./consts.scss";

.custom-arrow {
    &.swiper-button-next,
    &.swiper-button-prev {
        background-color: $transparent-white;
        border-radius: 50%;
        width: vh(6);
        height: vh(6);

        img {
            width: 50%;
        }

        &::after {
            display: none;
        }

        &.swiper-button-disabled {
            display: none;
        }
    }

    &.swiper-button-prev {
        img {
            scale: -1;
        }
    }
}

.swiper-pagination {
    .swiper-pagination-bullet {
        background-color: #f5f7f8;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        background-color: #d7e0e2;
    }
}

@media (max-width: $breakpoint) {
    .custom-arrow {
        display: none;
    }

    .swiper-container {
        padding: 0.5vh 0;

        .swiper-slide {
            display: flex;
            justify-content: center;
        }
    }
}
