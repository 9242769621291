@import "./consts.scss";

.grey-box-wrapper {
    margin-top: 2vh;
    background-color: $header;
    margin-bottom: 2vh;
    width: max-content;
    box-shadow: 0px 3px 16px $box-shadow-light;
    border-radius: 2vh;
    padding-right: 8vw;
    padding-left: 8vw;
}