@import "../../style/consts.scss";

.event-created-icon {
    width: 13vh;
    margin-top: 1.6vh;
}

.popup-event-created {
    height: fit-content;
}

.event-created-popup {
    direction: rtl;
    color: $texts;
    font-size: 1.1rem;
    margin-top: 1vh;
    max-width: 85%;

    width: 62.39vw;
    text-align: center;
    display: flex;
    flex-direction: column;


    .event-created-header {
        font-size: 1.5rem;
        margin: 1.8vh 0 3.5vh;
    }

    .event-created-title>* {
        margin: 0.5vh;
        line-height: normal;
    }

    .event-created-title {
        font-size: vh(3);
        line-height: 1.3rem;
        margin-bottom: 4vh;

        .pay-attention {
            margin-bottom: 1.5vh;
            font-size: vh(3.5);
            font-weight: 700;
        }
    }

    .event-created-text>* {
        margin: 1vh;
        font-size: vh(2);
    }

    .event-created-text {
        margin-bottom: 3vh;
        font-size: 1vw;
    }

    .purchesed {
        font-size: vh(2.5);
    }
}

@media screen and (max-width: $breakpoint) {
    .event-created-icon {
        width: 7vh;
        margin-top: 1vh;
    }

    .event-created-popup {
        .event-created-title {
            font-size: vh(2.5);
        }

        .event-created-title>* {
            line-height: 1.3rem;
        }

        .event-created-text {
            font-size: 2vw;
        }

        .purchesed {
            font-size: vh(2.7);
            margin-bottom: 5vh;
        }
    }
}