@import "./consts.scss";

.oval-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .oval-item {
        &.clicked {
            background-color: $selected-background-inputs;
            border: 2.5px solid $inputs-border;
        }
        background-color: $inputs-background-color;
        width: fit-content;
        min-width: 6vw;
        border-radius: 4vh;
        height: 4vh;
        border: 2.5px solid $inputs-border;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-left: 0.5vh;
        padding-right: 0.5vh;
        cursor: pointer;
        .oval-title {
            font-size: vh(2.2);
        }
        .oval-img-oval {
            height: 0.8vh;
        }
    }

    .input-error {
        margin-top: 1vh;
        margin-bottom: 0;
        width: 100%;
    }
}

@media screen and (max-width: $breakpoint) {
    .oval-box {
        .oval-item {
            min-width: 15vw;
            padding-top: 0.5vh;
            padding-bottom: 0.5vh;
            padding-left: 1vh;
            padding-right: 1vh;
            .oval-title {
                text-align: center;
            }
        }
    }
}
