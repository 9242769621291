@import "../../../../style/consts.scss";

.review-right-side {
    display: flex;
    flex-direction: column;

    .review-right-side-container {
        display: flex;
        flex-direction: column;
        text-align: right;
        width: min-content;

        .review-right-title {
            color: $texts;
            font-weight: 700;
            font-size: vh(4.8);
            margin-top: vh(6);
            margin-bottom: vh(3.5);
        }

        .review-right-paragraph {
            color: $texts;
            font-size: vh(2.5);
            font-weight: 700;
            margin-bottom: vh(2.7);
        }

        .review-right-gen-input {
            margin-bottom: vh(1);
        }

        .review-input-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            margin-bottom: vh(1);
            position: relative;

            .review-first {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                font-weight: 500;
                overflow: hidden;
                background-color: $inputs-background-color;
                border: 1px solid $inputs-border;
                height: vh(10);
                width: 30vw;

                .upload-arrow-image-back {
                    .review-first-pic {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        justify-content: center;

                        .upload-arrow-image {
                            margin-left: vh(1);
                            height: vh(4.5);
                        }
                    }
                }
            }

            .input-file-text {
                font-size: vh(2.2);
                width: 100%;
                height: 100% !important;

                position: absolute;
                cursor: pointer;
                opacity: 0;
                filter: alpha(opacity=0);
            }
        }

        .review-errors-first {
            color: $error;
            font-weight: 400;
            font-size: vh(1.7);
            text-align: right;
            margin-bottom: vh(1);
            height: vh(1);
        }

        .upload-arrow-images-second {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-start;
            width: 30vw;

            .review-pic-text {
                cursor: pointer;
                margin-bottom: vh(01);
                margin-right: 0.5vw;
                font-size: vh(2);
                color: $texts;
                border: unset;
                background-color: unset;
                text-decoration: underline;
                font-family: $font;
            }

            .img-border {
                border-radius: vh(0.5);
                height: vh(12);
                width: 35%;
                margin-bottom: vh(1);
                margin-top: vh(1);
                box-shadow: 0px 3px 6px $box-shadow;

                .selected-image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .review-right-button {
            align-self: flex-start;
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .review-right-side {
        height: vh(92);

        .review-right-side-container {
            width: 80vw;

            .review-right-title {
                font-size: vh(3.5);
            }

            .review-right-paragraph {
                margin-top: vh(-3);

                .artist {
                    font-size: 2.5vh;
                    margin-bottom: vh(1);
                }

                .date {
                    font-size: 2vh;
                    font-weight: 400;
                }
            }

            .review-right-gen-input {
                width: 100%;
            }

            .review-input-container {
                width: 100%;
                overflow: unset;

                .review-first {
                    border-radius: vh(3);
                    width: 100%;
                    height: vh(6);
                    background-color: #05b9b70f;
                }
            }

            .upload-arrow-images-second {
                width: 80vw;
            }
        }
    }
}
