@import "../../style/consts.scss";

.event-canceled {
    justify-content: center;

    .title {
        font: normal normal bold vh(3.7) / vh(4.8) Assistant;
        color: $dark-blue;
        margin: 5%;
    }
}

@media only screen and (max-width: $breakpoint) {
    .event-canceled {
        height: 50vh;

        .the-icon {
            width: 12vw;
        }

        .title {
            font-size: 3vh;
        }

        .the-button {
            margin-top: 5vh;

            .generic-button {
                width: auto;
                padding: 0.8vh 2.8vh;
            }
        }
    }
}
