@import "./consts.scss";

.vip-banner {
    position: absolute;
    width: 13vw;
    top: -4px;
    left: -4px;
    rotate: -90deg;
}

.vip-banner-agency {
    rotate: initial;
    right: -4px;
    top: -2.5px;
}

@media screen and (max-width: $breakpoint) {
    .vip-banner {
        width: 30vw;
    }
}
