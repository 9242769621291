@import "../../../../style/consts.scss";

.coupons-input {
    width: auto !important;
    height: auto;

    .web-link-input input {
        align-self: auto;
        width: 22.5vw;
    }

    .coupon-inputs-box {
        display: flex;
        align-items: center;
        gap: 2.5vw;
    }
}

@media (max-width: $breakpoint) {
    .coupons-input {
    
        .web-link-input input {
            width: 70vw;
        }
    
        .coupon-inputs-box {
            display: flex;
            align-items: center;
            gap: 2.5vw;

        }
    }
}
