@import "../../../../style/consts.scss";

.thank-you-card {
    background-color: $background-color-secondary;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    margin-top: 2rem;
    padding: 1rem 1rem 0;
    border-radius: 1rem;
    height: 12rem;
    aspect-ratio: 4/3;
    color: #0c414f;
    width: 70vmin;

    .profile-pic {
        align-self: center;
        margin-top: -2.5rem;
        box-shadow: 0 .25rem .5rem 0 $box-shadow;
        background-color: $background-color-primary;
    }

    .card-title {
        color: $texts;
        font-size: 1.125rem;
        font-weight: 700;
        flex: 1;
        margin: .5rem;
    }

    .card-content {
        text-align: right;
        overflow: hidden;
    }

    .horizontal-split {
        height: 1px;
        background-color: $background-color-left;
    }

    .event-details {
        font-size: .825rem;
        font-weight: 700;
        vertical-align: middle;
        padding: .5rem;
        flex: 1;
        direction: ltr;
    }
}