@import "./consts.scss";

.hearing-aids-input {
    .hearing-aids-input-container {
        display: flex;
        align-items: center;
        gap: 10%;
        margin-bottom: vh(2);
        flex-wrap: wrap;

        .hearing-aids-title {
            margin-bottom: 0;
        }

        .hearing-aids-error {
            color: $error;
            font-size: vh(1.7);
            text-align: right;
            margin-bottom: vh(2);
            height: vh(1);
            margin-right: 0.7vw;
        }
    }

    .hearing-aids-title {
        font-size: vh(2.5);
        font-weight: 600;
        margin-bottom: vh(1);
    }
}
