@import "../../../../style/consts.scss";

.event-happened-item-container {
    margin-top: vh(5);

    .little-titles {
        font-size: vh(2.9);
        color: $texts;
        font-weight: 700;
    }

    .events-happened-item {
        width: 82vw;
        color: $texts;
        background: $event-strip-background 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $box-shadow;
        margin-top: vh(3);
        font-size: vh(2.3);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2vh 2.5vw;

        .past-event-right-and-middle {
            width: 46vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .past-event-right {
                color: $texts;
                width: 60%;
                font-weight: 400;

                .event-name {
                    display: inline;
                    font-weight: 700;
                    margin-left: 0.5vw;
                }
            }

            .past-event-middle {
                display: flex;
                align-items: center;
                gap: 0.5vw;

                .img-ticket {
                    height: 1.2vw;
                }
            }
        }

        .button-before-text {
            display: flex;
            width: 15vw;

            .thank-you-pressed-container {
                display: flex;
                align-items: center;
                gap: 3vw;
                margin-right: 4vw;
                font-size: vh(2);

                .arrow-for-feedback {
                    width: vh(1.7);
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .event-happened-one-item {
        .events-happened-item {
            height: vh(17) !important;
        }
    }

    .generic-button.MuiButtonBase-root.thank-you {
        width: 38vw;
        height: 4vh;
        font-size: 3.8vw;
        border-radius: calc(var(--vh, 1vh) * 4);
        margin-left: 2vw;
    }
}

@media screen and(max-width: $breakpoint) {
    .event-happened-item-container {
        .events-happened-item {
            width: 83vw;
            justify-content: flex-start;
            gap: 1vw;
            padding: 0.5vh 2.5vw;
            padding-bottom: 1.5vh;

            .past-event-right-and-middle {
                width: 60%;
                flex-direction: column;

                .past-event-right {
                    margin-top: vh(1);
                    font-size: vh(2);
                    width: 100%;

                    .event-name {
                        display: block
                    }
                }

                .past-event-middle {
                    margin-top: vh(1);
                    font-size: vh(1.8);
                    font-weight: 700;
                    display: flex;
                    gap: 1.5vw;

                    .img-ticket {
                        height: vh(2);
                    }
                }
            }

            .button-before-text {
                .thank-you-pressed-container {
                    margin-right: 6vw;
                    gap: 5vw;
                    font-size: vh(2);

                    .arrow-for-feedback {
                        width: vh(1.3);
                    }
                }
            }
        }

    }

    .generic-button.MuiButtonBase-root.thank-you {
        font-size: 3vw;
    }
}