@import "../style/consts.scss";

.number-input-container {
    display: inline-flex;
    width: 15vh;
    aspect-ratio: 3;
    text-align: center;
    font-size: vh(2);
    font-weight: 500;
    box-shadow: 0px 2px 3px $box-shadow;
    border-radius: 4px;
    color: $texts;

    .plus-btn,
    .minus-btn {
        background-color: $background-color-left;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        &.disabled {
            opacity: 60%;
            cursor: default;
        }
    }

    .number-display {
        flex: 1;
        box-sizing: border-box;
        width: 100%;
        border: none;
        outline: none;
        text-align: center;
        font-size: vh(2);
        color: $texts;
    }
}
