@import "../../style//consts.scss";

.forgot-publish-popup {
    width: 40vw;
    height: 40vh;
    font-size: 3vh;

    h3 {
        margin-top: 1.5vh;
        margin-bottom: 8vh;
    }

    .publish-event {
        padding: 1.5vh 5vh;
    }
}

@media screen and (max-width: $breakpoint) {
    .forgot-publish-popup {
        width: 85vw;
    }
}
