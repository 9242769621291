@import "../../../../style/consts.scss";

.left-img-container {
    display: flex;
    justify-content: center;
}

.left-ticket-img {
    height: vh(20);
}

.left-title {
    font-size: vh(4);
    font-weight: 700;
    color: $dark-blue;
    width: vh(45);
    margin-top: vh(8);
    margin-bottom: vh(10);
    text-align: center;
}

//right side

.trash-can-container {
    cursor: pointer;

    &:hover {
        .trash-can-top {
            transform: translateX(-3px) rotate(-30deg) translateY(-3px);
        }
    }
}

.blabla {
    margin-top: vh(5);
    margin-bottom: vh(3);
}

.right-input-title {
    font-weight: 700;
    font-size: vh(4);
}

.right-input-inputs {
    margin-top: vh(3);
}

.right-input-side-title {
    font-weight: 700;
    font-size: vh(2.45);
    margin-bottom: vh(1);
}

.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
    border-style: unset;
}

.MuiList-root {
    .MuiMenuItem-root.Mui-selected {
        background-color: $inputs-border;
    }
}

.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
    padding-right: 0.4vw;
    font-family: $font;
}

.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    padding-right: unset;
}

.right-side .right-blue-btn {
    width: vh(20);
    height: vh(4.5);
    font-size: vh(2.5);
    margin-top: vh(2);
}

.right-side .right-yellow-btn {
    background-color: $btn-yellow;
    height: vh(6.5);
    font-size: vh(2.8);
}

.right-yellow-btn-container {
    margin-top: vh(4);
    margin-bottom: vh(4);
    margin-right: auto;
    width: fit-content;
}

.right-select select {
    font-weight: 600;
    width: 100%;
    height: vh(5);
}

.errors {
    margin-bottom: vh(1.5);
}

.error {
    margin-top: vh(-2);
}

.input-container {
    .special-input-container {
        position: relative;

        .special-input {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            height: vh(4.5);
            position: relative;
            align-items: center;
            color: #41596bd2;
            align-content: center;

            .add-category-btn {
                padding: vh(0.5);
                margin-right: 0.7%;
            }

            .special-input-icon {
                position: absolute;
            }
        }

        .selected-categories {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top: vh(1);
        }
    }
}

.org-profile-popups-container {
    display: flex;
    flex-wrap: wrap;
}

.inside-profile-ages {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width: $breakpoint) {
    .right-input-inputs {
        margin-top: vh(0);
    }

    .left-ticket-img {
        display: none;
    }

    .left-title {
        display: none;
    }

    .MuiSvgIcon-root.MuiSelect-icon {
        position: absolute;
        right: 52vw;
    }

    .right-select {
        // width: 76.5vw;

        select {
            width: 100%;
            border-radius: 15px;
            margin-right: unset;
        }

        .MuiInputBase-root.MuiOutlinedInput-root {
            border-radius: vh(1.7);
        }
    }

    .right-input-title {
        font-size: 6vw;
        margin-top: 3vh;
    }

    .right-input-title-false {
        position: absolute;
        font-size: 4.5vw;
        top: vh(16);
        text-align: center;
        left: vh(-0.1);
        width: 100vw;
    }

    .right-input-side-title {
        font-size: vh(2.7);
    }

    .right-side .right-yellow-btn {
        border-radius: vh(4);
        width: 22vw;
        margin-bottom: vh(7);
    }

    .right-side .right-blue-btn {
        border-radius: vh(4);
    }
}