@import "../../style/consts.scss";

.unpublished-draft-popup {
    width: 40vw;
    height: 36vh;
    justify-content: center;

    .the-icon {
        width: 7%;
    }

    .the-title {
        margin-bottom: 5vh;
    }

    .buttons {
        display: flex;
        gap: 1vw;
    }
}

@media screen and (max-width: $breakpoint) {
    .unpublished-draft-popup {
        width: 80vw;
        height: 50vh;

        .buttons {
            flex-direction: column;
            gap: 3vh;
        }

        .the-icon {
            width: 11%;
        }
    }
}