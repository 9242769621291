@import "./consts.scss";

.event-cover-wrapper {
    height: 100%;
    aspect-ratio: $event-image-ratio;
    position: relative;
    overflow: hidden;

    img {
        object-fit: cover;
        width: 100%;
        min-height: 100%;
    }
}
