@import "./consts.scss";

.profile-pic-wrapper {
    width: vh(6);
    height: vh(6);
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    img {
        object-fit: fill;
        width: 100%;
        min-height: 100%;
    }
}
