@import "../../../style/consts.scss";

.container-of-logos {
    max-height: vh(20);
    overflow: hidden auto;
    .inner-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-bottom: vh(1.85);
        margin-top: vh(1);

        .logo {
            background-color: $header;
            width: 3.2vw;
            height: 3.2vw;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            margin-right: 1vw;
        }

        .name {
            text-align: center;
            font: normal normal bold 30px/40px Assistant;
            color: $texts;
            margin-left: 1.5vw;
            font-size: vh(2.5);
        }
    }

    @include scrollBar-thin;
}

@media screen and (max-width: $breakpoint) {
    .container-of-logos {
        .inner-container {
            .logo {
                width: 8vw;
                height: 8vw;

                img {
                    width: 8.5vw;
                }
            }
        }
    }
}
