@import "../../../../style/consts.scss";

.entrance-tickets-pdf-inputs {
    .file-input-container {
        position: relative;
        margin: vh(2) 0;

        .file-btn {
            display: flex;
            align-items: center;
            padding: 0 5%;
            white-space: nowrap;
            overflow: hidden;
            width: 10vw;
            font-size: vh(1.9);

            .upload-text-and-logo {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                    width: 13%;
                    margin-left: 2%;
                }
            }
        }

        input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);
        }
    }
}
