@import "../../../../style/consts.scss";

.organization-upcoming-events {
    @include system-home-page-section-styles;
    background-color: $background-color-secondary;

    .no-events-container {
        width: fit-content;
        margin: 7vh auto 2vh;
        text-align: center;
        padding-left: 7vw;
        font-size: 2.5vh;
        color: $dark-blue;

        img {
            height: 10vh;
            display: block;
            margin: 0 auto 3vh;
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .organization-upcoming-events {
        .swiper-container {
            margin-right: -7vw;
            margin-top: 2vh;
        }

        .no-events-container {
            font-size: vh(2);
            margin: 2vh auto 1vh;

            img {
                height: 8vh;
            }
        }
    }
}
