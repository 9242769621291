.generic-switch-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    gap: 1vw;

    .switch-label {
        font-size: 2.5vh;
        font-weight: 600;
    }
}