@import "../../../../style/consts.scss";

.category-popup {
    height: 68vh;

    &.place-type {
        width: 70vh;
        height: 70vh;
        justify-content: space-around;

        .category-container {
            .category-box {
                width: 27vw;

                .category-item {
                    width: 80%;
                }
            }
        }
    }

    .category-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .title-img {
            width: 4vw;
        }
        .title {
            font: normal normal bold vh(3.8) / vh(4.8) Assistant;
            color: $texts;
            margin-top: vh(-3);
        }
        .category-box {
            height: vh(38);
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-content: flex-start;
            justify-content: center;
            margin-top: vh(3);
            overflow: hidden auto;
            @include scrollBar-thin;

            .category-item {
                width: 30%;
                background: $inputs-background-color;
                border: 1px solid $inputs-border;
                height: vh(8);
                margin: vh(1);
                padding: vh(0.2);
                display: flex;
                align-items: center;
                .category-check {
                    margin-right: 2vw;
                    margin-left: 2vw;
                }
                .category-name {
                    font-family: $font;
                    width: 52vw;
                    text-align: right;
                    font-size: 1.3vw;
                    overflow-wrap: break-word;
                }
            }
            .no-found-type {
                font-size: vh(3);
                overflow-wrap: break-word;
                color: $texts;
                font-family: $font;
                font-weight: 500;
            }
        }

        @include scrollBar-thin;
    }
}

@media (max-width: 1100px) {
    .category-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 3.5vw;
        }

        .category-box {
            .category-item {
                width: 40vw;
                .category-name {
                    font-size: 2vw;
                }
            }
        }
    }
}
@media (max-width: $breakpoint) {
    .category-popup {
        height: 68vh;

        &.place-type {
            width: 90%;

            .category-container {
                .category-box {
                    width: 100%;
                    height: 54vh;
                }
            }
        }

        .category-container {
            .title {
                font-size: 4.5vw;
            }
            .category-box {
                .category-item {
                    width: 40%;
                    .category-name {
                        font-size: 3.5vw;
                    }
                }
            }
        }
    }
}
