@import "./consts.scss";

.agency-profile {

  .profile,
  .details {
    margin-top: 5vh;
  }

  .input-error,
  .add-event-errors-first {
    color: $error;
    font-size: vh(1.7);
    margin-bottom: vh(1);
    min-height: vh(1);
  }

  .active {
    font-weight: 600;
  }

  .tab.Mui-disabled {
    pointer-events: all;
    cursor: not-allowed;
  }

  .tabs {
    margin-top: 3vh;
  }

  .tab {
    font-size: 2.5vh;
  }

  .input-title {
    font-weight: 600;
    color: $texts;
    text-align: right;
    font-size: vh(2.3);
    margin-top: calc(var(--vh, 1vh) * 1);
    margin-bottom: calc(var(--vh, 1vh) * 1);
  }

  .agency-sign-up-left {
    height: vh(87);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: vh(3);

    .agency-sign-up-left-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 700;
      margin-top: vh(-8);

      .sofa-icon {
        width: vh(10);
        margin-bottom: vh(2);
      }
    }

    .list-container {
      margin-top: vh(-2);

      .list {
        list-style: none;
        text-align-last: right;
        width: fit-content;
        font-weight: 600;
        margin-right: 4.5vw;
      }

      .list-title {
        font-weight: 800;
      }
    }
  }

  .MuiAutocomplete-root .MuiInputBase-hiddenLabel {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .inputs-container {
    width: 30vw;
    margin-bottom: 5vh;

    .agency-sign-up-right-title {
      font-weight: 700;
      font-size: vh(4);
      margin-top: vh(5);
      margin-bottom: vh(1);
      width: max-content;
    }

    .line-break {
      margin-bottom: 2vh;
      margin-top: 2vh;
      width: 50%;
      background-color: #00000040;
      height: 1px;
      margin-left: 25%;
    }

    .agency-sign-up-right-button {
      min-height: vh(6.5);
      width: 10vw;
      margin-top: vh(2);
    }

    .agency-sign-up-right-checkboxes {
      display: flex;
      flex-direction: column;
    }

    .checkbox-item {
      margin: 1vh 0;
    }

    .the-checkbox {
      background-color: #ffffff;
    }

    .checkbox-title {
      font-size: vh(1.8);
    }

    .margin-bottom {
      margin-bottom: 1vh;
    }
  }

  textarea {
    resize: none;
  }

  .contacts-wrapper {
    margin-bottom: 2vh;
    position: relative;
  }

  .agency-sign-up-errors {
    color: $error;
    font-weight: 400;
    font-size: vh(1.7);
    text-align: right;
    margin-bottom: vh(1);
    height: vh(1);
  }

  .agency-sign-up-right-checkboxes {
    display: flex;
    flex-direction: column;
  }

  .checkbox-item {
    margin: 1vh 0;
  }

  .the-checkbox {
    background-color: #ffffff;
  }

  .checkbox-title {
    font-size: vh(1.8);
  }

  .margin-bottom {
    margin-bottom: 1vh;
  }
}

textarea {
  resize: none;
}

.contacts-wrapper {
  margin-bottom: 2vh;
  position: relative;
}

.agency-sign-up-errors {
  color: $error;
  font-weight: 400;
  font-size: vh(1.7);
  text-align: right;
  margin-bottom: vh(1);
  height: vh(1);
}

// @media only screen and (max-width: $breakpoint) {
@media screen and (max-width: $breakpoint) {
  .agency-profile {
    .agency-sign-up-left {
      display: none;
    }

    .inputs-container {
      width: 85vw;

      .agency-sign-up-right-title {
        font-size: vh(2.8);
      }

      .agency-sign-up-right-button {
        border-radius: vh(20);
        width: fit-content;
        padding: 0 3vh 0 3vh;
        font-size: vh(3.2);
        margin-bottom: vh(4);
      }

      .signup-logo-title {
        font-size: vh(2.2);
      }
    }
  }
}