@import "../../../style/consts.scss";

.welcome-user {
    padding-top: vh(0.5);
    padding-bottom: vh(0.5);
    padding-right: 2vw;
    border-right: 0.1vw white solid;
    cursor: default;
}

@media screen and (max-width: $breakpoint) {
    .welcome-user {
        max-width: 30vw;
        overflow: hidden;
        border-right: none;
    }
}