@import "../../../style/consts.scss";

.details {
    font-size: 1.125rem;
    padding-bottom: 11vh;
}

.contact-name {
    margin-top: 1.5vw;
    margin-bottom: 0.8vw;
    color: $texts;
    font-weight: 700;
    font-size: vh(3);
}

.all-contact-details {
    display: flex;
}

.contact-details {
    margin-bottom: 0.5vw;
    margin-left: 1.2vw;
    color: $texts;
    font-weight: 600;
    font-size: vh(2.7);
}

.event-info-box {
    display: flex;

    .event-details {
        width: 70%;
        margin-left: vh(2);
        margin-bottom: vh(20);
    }
}

.details-archive {
    display: flex;
    flex-direction: column;
    margin-right: vh(1.5);
    width: 55vw;
}

.big-image {
    // margin-top: 1vh;
    position: relative;
    width: 100%;
    aspect-ratio: $event-image-ratio;
    overflow: hidden;

    .yellow-sticker-wrapper {
        position: absolute;
        left: 10%;
        bottom: 70%;
    }
}

// the big picture
.sponge-bob {
    width: 100%;
    object-fit: cover;
    position: absolute;
}

.the-name {
    margin-right: 10.833vw;
    text-align: right;
    font-size: vh(4.5);
    font-weight: 700;
    letter-spacing: 1.35px;
    color: $dark-blue;
    margin-bottom: 0;
}

.the-info {
    margin-right: 10.833vw;
    margin-top: vh(1);
    margin-bottom: vh(6);
    text-align: right;
    letter-spacing: 1.08px;
    color: $dark-blue;
    opacity: 1;
    font-size: vh(3);
}

.limitPara {
    white-space: pre-line;
    overflow-wrap: break-word;
    margin-right: 10.833vw;
    margin-top: vh(8);
    text-align: right;
    letter-spacing: 0.68px;
    color: $texts;
    opacity: 1;
}

.limitPara-poster {
    //  width: 100%; // this is romTorrab fix i comment it while merging conflict
    overflow-wrap: break-word;
    text-decoration: underline;
    font-weight: 700;
    margin-right: 10.833vw;
    margin-left: 42vw;
    margin-top: vh(8);
    text-align: right;
    letter-spacing: 0.68px;
    color: $dark-blue;
    opacity: 1;
    cursor: pointer;
}

.tickets-title {
    margin-top: vh(6);
    text-align: right;
    margin-right: 10.833vw;
    color: $texts;
    font-weight: 700;
    font-size: vh(3);
}

.hearing-aids-text {
    color: $texts;
    margin-right: 10.833vw;
    margin-top: vh(2.5);
    font-size: vh(2.5);

    .tickets-title {
        margin-right: 0;
        margin-bottom: vh(2);
    }

    .choose-amount {
        margin-bottom: vh(2);
        font-size: vh(2.8);
        font-weight: 600;
    }

    img {
        width: vh(2.5);
        vertical-align: middle;
        margin-left: vh(1.5);
    }
}

.seat-groups-container {
    margin-right: 10.833vw;
    display: flex;
    flex-direction: column;
}

.benefit-event-extras {
    .user-row {
        width: 60vw;
        height: 13vh;
    }
}

.read_button {
    text-align: right;
    letter-spacing: 0.68px;
    color: $dark-blue;
    opacity: 1;
    border: none;
    background: none;
    float: right;
    margin-right: 10.833vw;
    cursor: pointer;
    font-size: vh(2);
    text-decoration: underline;
    font-weight: 700;
}

.footer-status-order {
    margin-top: vh(10);
}

@media screen and (max-width: $breakpoint) {
    .seat-groups-container {
        width: 85vw;
    }

    .details {
        font-size: 1rem;
        padding-bottom: 20vh;
    }

    .details-archive {
        width: 70vw;
    }

    .limitPara {
        width: 75vw;
        margin-left: 15vw;
        font-size: vh(2.77);
    }

    .the-name {
        font-size: vh(3.5);
    }

    .the-info {
        font-size: vh(2.5);
    }

    .tickets-title,
    .contact-name,
    .status-order-title {
        font-size: vh(2.5);
    }

    .contact-details {
        font-size: vh(2);
    }

    .event-info-box {
        flex-wrap: wrap;

        .event-details {
            margin-bottom: 4vh;
        }

        .order-status {
            width: 90%;
            margin: 0;
        }
    }
}
