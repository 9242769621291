@import "./consts.scss";

// this serves as scss for all titles
.the-title {
    text-align: center;
    font: normal normal bold vh(3.7) / vh(4.8) Assistant;
    letter-spacing: 1.08px;
    color: $dark-blue;
    margin-bottom: vh(2.59);
}

// if popup was sent children
.the-text {
    text-align: center;
    font: normal normal normal vh(2.7) / vh(2.5) Assistant;
    letter-spacing: 0.81px;
    color: $texts;
    text-transform: uppercase;

    // background-color: aqua;
    height: vh(6);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

// this is the scss box for the coupon - inside sits the code and copy button
.the-coupon {
    display: flex;
    justify-content: space-between;
    background-color: rgba(180, 255, 255, 0.48);
    margin-right: 22vw;
    margin-left: 22vw;
    margin-bottom: vh(4);
    padding: vh(1.1) 1.45vw;
    align-items: baseline;
    box-shadow: 0px 3px 6px $box-shadow;
}

.items-in-button {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 12vw;

    .icon-in-button {
        height: 3vh;
        margin-top: 0.5vh;
    }
}

//copy button
.coupon-button {
    all: unset;
}

// makes sure that whatever comes through the children will be centered.
.the-children {
    display: flex;
    flex-direction: column;
}

.the-popup.coupon {
    height: auto;
}

// this is where the close button sits
.the-header {
    display: flex;
}

.add-back-color {
    // background: linear-gradient(rgba(0, 0, 0, 0.725), 63%, rgba(255, 255, 255, 0));
    // opacity: 100%;
    // linear-gradient(.25turn, black, 10%, white);
    position: absolute;
    top: 0;
    z-index: 4;
    width: 100%;

    .close-button {
        display: flex;

        .close-button-text {
            // color: white;
            color: $texts;
            width: 100%;
            font-size: 8.7vh;
            font-weight: 200;
        }
    }
}

.the-popup {
    background-color: $background-color-primary;
    border-color: black;
    border-radius: vh(3.148);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    width: 62.39vw;
    height: vh(59.44);
    text-align: center;
    display: flex;
    flex-direction: column;
}

.details-popup {
    height: vh(45);
    width: 45%;
    justify-content: unset;

    .flex-center {
        .agency-changed-details-text {
            color: $texts;
            font-size: 1.6rem;
            max-width: 85%;
            margin-bottom: -4vh;

            .pay-attention {
                margin-bottom: 1.5vh;
                font-size: vh(3.1);
                font-weight: 700;
            }
        }
    }

    img {
        width: vh(12);
    }
}

.feedback-popup-edit {
    height: vh(45);
    width: vh(78);

    .the-title {
        margin: auto;
    }

    .close-button-container {
        margin: vh(1);
    }

    .flex-center {
        align-items: flex-start;
        margin: auto;
        margin-top: 0;
    }

    .the-button {
        align-self: center;
        margin-top: 2vh;
    }
}

.feedback-popup-delete {
    height: vh(35);
}

.details-popup {
    height: vh(45);
    width: 45%;
    justify-content: unset;

    .flex-center {
        .agency-changed-details-text {
            color: $texts;
            font-size: 1.6rem;
            max-width: 85%;
            margin-bottom: -4vh;

            .pay-attention {
                margin-bottom: 1.5vh;
                font-size: vh(3.1);
                font-weight: 700;
            }
        }
    }

    img {
        width: vh(12);
    }
}

// any content that is printed on the popup
.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.save-tickets-popup-wrapper {
    height: vh(65);
    width: 60vw;

    .the-icon {
        height: vh(10);
        width: unset;
    }

    .save-tickets-text {
        margin-bottom: vh(6);
        font-weight: 500;
        color: $texts;

        .pay-attention {
            font-weight: 600;
            margin-bottom: vh(1);
        }

        .timer {
            font-weight: 700;
            font-size: vh(4);
            margin-top: 0;
            margin-bottom: vh(1)
        }
    }
}

.close-button-container {
    //just the x button and its needs
    margin-top: vh(1.5);
    margin-right: 2.5vw;

    .close-button-text {
        color: $texts;
        font-size: vh(6);
    }
}

// currently my fix is working therefor might remove all additional icon css.
.the-icon {
    display: block;
    width: vh(10.3);
    object-fit: contain;
    margin-bottom: vh(2.1);
}

// as said above might be deleted
.the-icon-ticket-amount {
    display: block;
    margin-left: 27.8vw;
    margin-right: auto;
    width: 5.7vw;
    height: vh(6);
    margin-bottom: vh(2.59);
    margin-top: vh(8.7);
}

// super important to order the right amount of tickets
.increase-decrease {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-color: rgba(5, 119, 115, 1);
    border: 0.5vh solid $dark-blue;
    margin: vh(3) 25.7vw;
    padding: vh(1.2) 0vw;
    border-radius: vh(1);
    min-width: vh(19);
    width: 13vw;
    height: vh(7);
}

.num-of-tickets {
    font: normal normal 800 vh(4.1) / vh(5.4) Assistant;
    color: rgba(65, 89, 107, 1);
    background-color: unset;
    border: none;
    text-align: center;
    width: 20%;

    &:focus {
        outline: none;
        /* Removes the border when the input is clicked */
    }
}

.creasing-buttons {
    font-weight: 800;
    color: rgba(65, 89, 107, 1);
    padding: unset;
    margin: unset;
    background-color: transparent;
    border: none;
    outline: none;

    &:disabled {
        color: rgba(65, 89, 107, 0.5);
        cursor: not-allowed;
    }
}

.the-popup.how-many {
    height: fit-content;
    max-height: vh(95);

    .generic-button.yellow.small {
        margin-bottom: vh(4);
        font-size: vh(3);
        width: 16vw;
    }
}

.the-popup.guide-popup {
    height: fit-content;
    width: 45vw;

    .the-icon {
        width: vh(12);
        margin-bottom: vh(3);
    }
}

.choose-user-for-contact {
    .the-title {
        width: 60vw;
    }

    .the-checkbox {
        border: 1px solid $dark-blue;
    }

    .users-list {
        width: 55vw;
        height: 21.5vh;
        overflow-y: scroll;
        margin-bottom: 2vh;
        font-size: 0.75rem;

        .user-row {
            display: flex;
            align-content: center;
            align-items: center;
            gap: inherit;
            background-color: white;
            margin-bottom: inherit;
            border-radius: 0.4rem;

            .user-details {
                display: flex;
                width: 40vw;
                justify-content: space-between;
                align-items: center;

                .name-user-row {
                    margin-right: 0;
                    font-size: 0.95rem;
                    font-weight: 600;
                    min-width: 10vw;
                    text-align: right;
                }

                .email-details {
                    overflow: hidden;
                    width: 10vw;
                    text-overflow: ellipsis;
                }

                .phone-details {
                    min-width: 10vw;
                    text-align: right;
                }
            }
        }
    }

    .users-list::-webkit-scrollbar {
        width: 0.4vw;
    }

    .users-list::-webkit-scrollbar-track {
        width: 0.4vw;
    }

    .users-list::-webkit-scrollbar-thumb {
        background-color: $scroll-color;
    }

    .users-list::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    .the-icon {
        margin-top: -4vh;
    }
}

.the-popup.how-many-seat-groups {
    .seat-groups-container-order {
        max-height: 27vh;
        overflow: hidden auto;
        margin-top: vh(2);
        padding-left: vh(2);
        padding-right: vh(2);
        padding-bottom: vh(2);
        margin-bottom: vh(1.5);
    }

    .seat-groups-container-order::-webkit-scrollbar {
        width: 0.2vw;
    }

    .seat-groups-container-order::-webkit-scrollbar-track {
        width: 0.2vw;
    }

    .seat-groups-container-order::-webkit-scrollbar-thumb {
        background-color: $scroll-color;
    }

    .seat-groups-container-order::-webkit-scrollbar {
        display: none;
    }
}

// from the sentTickets popup
.sent-tickets {
    padding-top: 6vh;
}

.the-sent-tickets-text {
    text-align: center;
    letter-spacing: 0.68px;
    color: $texts;
    padding: 0 17vw;
    margin-bottom: vh(3);
    height: vh(12);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: vh(2.2);
    margin: 3vh 0 7vh;

    .sent-tickets-text-main>* {
        margin: 1vh;
    }

    .sent-tickets-text-main {
        .user-email {
            align-self: center;
            font-weight: bold;

            p {
                direction: ltr;
                display: inline-block;
                margin: 0;
            }
        }
    }
}

.delete-event {
    background-color: $background-color-primary;
    border-color: black;
    border-radius: vh(1.2);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    width: 40vw;
    height: vh(40);
    text-align: center;

    .delete-event-title {
        margin: 4vw 5vw;
        text-align: center;
        font: normal normal bold vh(3.7) / vh(4.8) Assistant;
        letter-spacing: 1.08px;
        color: $texts;
        margin-bottom: vh(2.59);
        font-size: 2.3vw;
    }

    .delete-event-buttons {
        display: flex;
        justify-content: space-evenly;
        margin-top: 3.5vw;
        margin-right: 3vw;
        margin-left: 3vw;
    }
}

@keyframes tilt {
    25% {
        transform: rotate(15deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-15deg);
    }
}

.contact-us {
    width: unset;
    height: vh(40);

    .thumbs-up-popup {
        display: flex;
        flex-direction: column;
        width: 40vw;

        .close-button-thumbs-up {
            color: $texts;
            font-size: vh(6);
        }

        .title-and-thumbs-up {
            width: 40vw;

            .the-title-thumbs-up {
                margin: 1vw 5vw;
                text-align: center;
                font: normal normal bold vh(3.7) / vh(4.8) Assistant;
                letter-spacing: 1.08px;
                color: $texts;
                margin-bottom: vh(2.59);
                font-size: 2.3vw;
            }

            .animation-thumbs-up {
                animation: tilt 3s infinite;

                .thumbs-up {
                    margin-top: vh(2);
                    width: 7vw;
                }
            }
        }
    }
}

.popup-with-img {
    background-color: $background-color-primary;
    border-color: black;
    border-radius: vh(1.2);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    width: 55vw;
    height: vh(75);
    display: flex;
    flex-direction: column;

    .flex-center {
        height: vh(65);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .close-button-text {
            color: $texts;
            font-size: vh(6);
        }

        .photo-popup {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 55vw;
            height: vh(75);

            .the-img {
                margin-bottom: vh(1);
                max-height: vh(64);
                max-width: 40vw;
            }
        }
    }
}

.full-screen-popup {
    position: absolute;
    bottom: 5%;
    width: fit-content;
    max-width: 94%;
    height: 94%;
    overflow: hidden;
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;

    .tools {
        position: absolute;
        left: 2.8%;
        bottom: 2.8%;
        z-index: 3;
        display: flex;
        flex-direction: column;

        .Zoom-icon {
            width: 4.136vw;
            height: 6.11vh;
            color: #ececf2;
            background-color: #41596b;
            border-radius: 5px;
            margin-top: 1vh;
            cursor: pointer;
        }
    }

    .image-container-for-fullScreen {
        img {
            height: 100vh;
        }
    }
}

.full-screen-popup:active {
    cursor: grabbing;
}

@media (max-width: 770px) {
    .the-popup {
        width: 90%;

        .items-in-button {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 22vw;
        }
    }

    .save-tickets-popup-wrapper {
        width: 85vw;
        height: vh(60);
        padding: 0 2vw
    }

    .how-many-popup-img {
        display: none;
    }

    .close-button {
        font-size: vh(5);

        .close-button-text {
            margin-bottom: 1.5vh;
            font-size: vh(4.5);
        }
    }

    //dont delete - ask noa

    // .the-popup.category {
    //     width: 100vw;
    //     height: vh(100);
    //     border-radius: unset;
    // }
    //

    .the-popup.how-many {
        width: 90vw;
        height: vh(45);

        .the-header {
            .close-button-container {
                .close-button-text {
                    font-size: vh(7);
                }
            }
        }

        .flex-center {
            margin-top: vh(-1.2);

            .the-title {
                font-size: vh(3.4);
            }

            .the-children {
                .the-text {
                    font-size: vh(2.6);
                    margin-bottom: vh(0.5);

                    .show-name {
                        font-size: vh(2.8);
                    }
                }
            }

            .generic-button.yellow.small {
                height: vh(5.3);
                font-size: vh(3.2);
                width: vh(22);
            }

            .increase-decrease {
                border-radius: 2vw;
                height: 5vh;
                min-width: 36vw;
                align-items: center;
            }
        }
    }

    .feedback-popup-edit {
        .edit-feedback-text {
            width: 70vw;
        }
    }

    .the-popup.sent-tickets {
        width: 93vw;
        height: vh(55);

        .the-sent-tickets-text {
            padding: 0;
            height: vh(15);
            display: flex;
            align-items: center;
        }

        .the-title {
            font-weight: 600;
            font-size: vh(3);
        }

        .generic-button.yellow.big {
            height: vh(6);
            width: fit-content;
            padding-right: 6vw;
            padding-left: 6vw;
            border-radius: vh(10);
            font-size: vh(2.8);
        }

        .the-icon {
            width: vh(9);
        }
    }

    .the-popup.joining-conditions {
        width: 90vw;
        height: vh(35);
        outline: none;

        .joining-conditions {
            display: flex;
            gap: 5vw;
            align-items: center;
            justify-content: space-between;
            margin-top: vh(2);

            img {
                width: vh(6);
            }

            .icon-text {
                width: 22vw;
                font-size: vh(2.3);
                font-weight: 700;
                color: $texts;
            }
        }

        .the-title {
            font-size: vh(2.5);
        }
    }

    .join-us-Agency {
        width: 90vw;
        height: vh(55);
        color: $texts;
        outline: none;
    }

    .join-us-img {
        width: vh(8);
    }

    .join-us-title {
        font-size: vh(2);
        font-weight: 700;
    }

    .join-us-list {
        font-size: vh(2.3);
        font-weight: 600;
    }

    .join-us-list-title {
        margin-top: vh(4);
        font-size: vh(2.3);
        font-weight: 800;
    }

    .delete-event {
        width: 85vw;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .delete-event-title {
            margin-top: vh(1);
            font-size: vh(3);
            margin-bottom: vh(5);
        }

        .delete-event-buttons {
            justify-content: space-between;
            width: 70vw;

            .text-on-button {
                font-size: 3vh;
            }
        }
    }

    .contact-us {
        width: unset;
        height: vh(28);

        .thumbs-up-popup {
            width: 75vw;

            .close-button-thumbs-up {
                font-size: vh(5);
            }

            .title-and-thumbs-up {
                width: 75vw;

                .the-title-thumbs-up {
                    margin-top: vh(-0.5);
                    font-size: 6vw;
                }

                .animation-thumbs-up {
                    .thumbs-up {
                        margin-top: vh(1);
                        margin-bottom: vh(1);
                        width: 16vw;
                    }
                }
            }
        }
    }

    .popup-with-img {
        width: 85vw;
        height: vh(80);
        justify-content: center;

        .flex-center {
            height: vh(70);
            width: 100%;
            justify-content: center;
            align-items: center;

            .photo-popup {
                width: 100%;

                .the-img {
                    width: 75vw;
                    max-width: none;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .the-popup.the-popup.how-many-seat-groups {
        width: 95vw;
        min-height: vh(60);
    }
}

@media screen and (max-width: $breakpoint) {
    .choose-user-for-contact {
        .the-title {
            font-size: 1.2rem;
            width: fit-content;
        }

        .users-list {
            .user-row {
                align-items: center;
                justify-content: center;

                .user-details {
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: center;

                    .email-details {
                        overflow: visible;
                        width: fit-content;
                        text-align: center;
                    }

                    .name-user-row {
                        text-align: center;
                    }

                    .phone-details {
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .the-button {
        .yellow {
            font-size: vh(2);
        }
    }
}

// remove the arrows fron input number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}