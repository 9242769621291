@import "../../../../style/consts.scss";

@mixin flex($direction, $content, $items) {
    display: flex;
    flex-direction: $direction;
    justify-content: $content;
    align-items: $items;
}

.events-ordered {
    @include system-home-page-section-styles;
    background-color: white;
    color: $texts;
    font-size: vh(20.68px);
    font-family: $font;
    max-width: 100vw;
    height: 79vh;
    background-color: $background-color-primary;


    .no-upcoming-events-container {
        display: flex;
        align-items: center;
        height: 12vw;

        .no-upcoming-events {
            width: 100vw;
            padding: 4.5vw 0 2.5vw 0;
            background-color: rgba($color: $event-strip-background, $alpha: 0.8);
            right: 0;
            position: absolute;
            text-align: center;
            font-size: vh(2.5);
            opacity: 0.75;
        }

        .no-upcoming-events-img {
            position: absolute;
            padding-bottom: 7vw;
            right: 0;
            z-index: 2;
            height: 6vw;
            width: 100vw;
        }
    }
}

.skeleton-container {
    // margin all elements execpt the ones with the stick to the right class
    // * {
    //     margin-right: vh(13);
    // }

    // .stick-to-right {
    //     margin-right: 6.5vw;

    //     * {
    //         margin-right: 0;
    //     }
    // }
    margin: 5vh 7vw;

    .skeleton-main-title {
        width: vh(25);
        height: vh(8);
        // margin: vh(4);
    }

    // .skeleton-sub-title {
    //     width: vh(18);
    //     height: vh(7);
    //     margin-bottom: vh(2);
    // }

    // .skeleton-slide-container {
    //     display: flex;
    //     gap: vh(2);
    //     flex-wrap: nowrap;
    //     margin-bottom: vh(2);

    //     .skeleton-slide {
    //         width: 46.5vw;
    //         height: vh(50);
    //         border-radius: 4px/6.7px;
    //     }
    // }

    .skeleton-slide-container2 {
        display: flex;
        flex-direction: column;
        gap: vh(2);
        margin-top: vh(4);
        // margin-bottom: vh(2);
        // margin-right: unset;

        .skeleton-slide {
            width: 100%;
            height: vh(10);
            border-radius: 4px/6.7px;
        }
    }
}

@media (max-width: $breakpoint) {
    .events-ordered {
        height: 82vh;

        .events-ordered-title {
            color: $dark-blue;
            font-size: vh(4);
            margin-top: vh(33);
        }

        .little-titles {
            font-size: vh(3);
            padding-bottom: vh(6);
        }

        .no-upcoming-events-container {
            margin-bottom: vh(5);

            .no-upcoming-events {
                padding: 8vw 0 4vw 0;
            }

            .no-upcoming-events-img {
                padding-bottom: 12vw;
                height: 10vw;
            }
        }

        .swiper-container {
            width: 100%;
            margin-top: vh(4);

            .slide-content {
                width: 100%;
                height: unset;
                height: vh(40);
                border-radius: unset;
                box-shadow: unset;

                .swiper-pics {
                    height: vh(25);
                }

                .events-slide-item-right {
                    font-size: vh(2.5);
                }
            }
        }
    }

    .skeleton-container {
        .skeleton-main-title {
            // margin-right: vh(4);
        }

        // .skeleton-sub-title {
        //     margin-right: vh(4);
        //     height: vh(3);
        //     width: vh(10);
        //     margin-bottom: vh(4);
        // }

        // .skeleton-slide-container {
        //     flex-direction: column;
        //     align-items: center;
        //     margin-right: 0;

        //     .skeleton-slide {
        //         width: 100vw;
        //         height: vh(38);
        //         margin-bottom: vh(8);
        //     }
        // }

        .skeleton-slide-container2 {
            // align-items: flex-end;
            width: 100%;

            .skeleton-slide {
                // width: 86%;
                height: vh(10);
            }
        }
    }
}