@import "../../../style/consts.scss";

.request-extra-tickets {
    color: $texts;
    font-size: vh(2.2);
    font-weight: 600;
    vertical-align: middle;
    display: flex;
    align-items: center;
    gap: 0.3vw;
    margin-right: 10.8vw;

    .extra-tickets-btn {
        padding: vh(0.4) vh(2);
        margin-right: 1vw;
        .text-on-button {
            font-size: vh(2.2);
        }
    }
}
