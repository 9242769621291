@import "../../../../style/consts.scss";

.benefits-page {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .subheading-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .main-subheading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: -1vh 0 2vh;
        color: $texts;
    }

    .left-subheading {
        align-self: flex-end;
        margin: 3vh 0 0 5vw;
    }

    .ticket-img {
        height: 0.825em;
        margin: 0 0 0.1em 0.5em;
        vertical-align: middle;
    }

    .benefit-events-container {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}