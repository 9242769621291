@import "../../../../style/consts.scss";

.add-more-tickets {
    width: 30vw;
    color: $dark-blue;
    padding-top: 4vh;
    padding-bottom: 6vh;

    h2 {
        font-size: 3.4vh;
        margin-bottom: 1.5vh;
    }

    .event-details {
        margin-bottom: 4vh;
        font-size: 2.3vh;
    }

    .ticket-worth-input {
        display: flex;
        gap: 0.5vw;

        input {
            width: 100%;
            align-self: flex-start;
        }

        .shekel-sign {
            font-size: vh(3);
            position: relative;
            top: 2vh;
        }
    }

    .add-event-input-title {
        font-size: vh(2.5);
        font-weight: 600;
        margin-bottom: vh(1);
    }

    .add-event-errors-third,
    .error {
        @include input-error;
    }

    .all-multiple-seatgroups-container {
        margin-top: vh(2);

        .upload-multiple {
            margin-bottom: 0.5vh;
            cursor: pointer;

            input {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                opacity: 0;
                filter: alpha(opacity=0);
            }
        }
    }

    .file-input-container {
        position: relative;
        margin-top: vh(2);
        height: vh(4);

        .upload-file-btn {
            height: 100%;
            display: flex;
            align-items: center;
            background-color: $inputs-background-color;
            border: vh(0.15) solid $inputs-border;
            padding: 0 5%;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            min-width: 10vw;
            width: max-content;
            max-width: 12vw;

            &.long {
                width: 100%;
                max-width: unset;
            }

            .upload-text-and-logo {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: vh(1.9);
                font-weight: 400;

                img {
                    width: 1.8vh;
                    margin-left: 2%;
                }
            }
        }

        input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);
        }
    }

    .buttons-container {
        display: flex;
        justify-content: flex-end;
        gap: 4vw;
        padding-top: 2vh;

        .cancel-btn {
            text-decoration: underline;
            border: 0;
            font-size: 1.5vw;
            font-weight: 700;
            color: $dark-blue;
            background-color: transparent;
            cursor: pointer;
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .add-more-tickets {
        width: 85vw;
        height: 82.3vh;

        .buttons-container {
            padding-bottom: 3vh;

            .MuiButtonBase-root {
                height: 5vh;
            }

            .cancel-btn {
                font-size: 5vw;
                min-width: 30vw;
                border-radius: vh(4);
            }
        }
    }
}