@import "./consts.scss";

.upload-event-image {
    width: 100%;

    .upload-box {
        position: relative;
        overflow: hidden;
        background-color: $inputs-background-color;
        border: 1px solid $inputs-border;
        width: 100%;
        aspect-ratio: $event-image-ratio;
        margin-bottom: vh(0.5);
        cursor: pointer;

        .upload-image {
            font-weight: 500;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .upload-arrow-image {
                height: vh(4);
                opacity: 0.7;
            }
        }

        .selected-image {
            width: 100%;
            object-fit: cover;
            position: absolute;
        }
    }

    .edit-delete-box {
        display: flex;
        gap: vh(3);
        margin-top: 1vh;

        .edit-photo-cordinates,
        .delete-photo {
            &:hover {
                cursor: pointer;
            }

            display: flex;
            align-items: center;
            width: fit-content;
            border: 1px solid $box-shadow-light;
            box-shadow: 0px 3px 6px $box-shadow;
            border-radius: 50%;
            padding: vh(1);

            .edit-icon,
            .delete-icon {
                width: vh(2.5);
            }
        }
    }
}

.poster-title-box {
    display: flex;
    align-items: center;

    .trash-can-container {
        display: flex;
        flex-direction: column;

        img {
            width: vh(2);
        }
    }
}

.upload-event-poster-image {
    width: 100%;
    height: 100%;

    .upload-poster-box {
        position: relative;
        overflow: hidden;
        background-color: $inputs-background-color;
        border: 1px solid $inputs-border;
        width: 100%;
        height: calc(var(--vh, 1vh) * 4.5);
        // aspect-ratio: $event-image-ratio;
        margin-bottom: vh(0.5);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        p {
            margin-right: 1vh;
            overflow: hidden;
            text-overflow: ellipsis;
            // font-size: calc(var(--vh, 1vh) * 1.8);
        }

        .upload-image-poster {
            font-weight: 400;
            font-size: vh(1.8);
            width: 100%;
            height: 100%;
            justify-content: flex-start;
            display: flex;
            align-items: center;

            .upload-arrow-image {
                opacity: 0.7;
                margin-right: 0.9vw;
                margin-left: 0.16vw;
                width: 1.2vw;
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .upload-event-image {
        .upload-box {
            border-radius: vh(2);
            border: 1.5px solid $inputs-border;
            width: 83vw;
        }
    }

    .upload-event-poster-image {
        .upload-poster-box {
            border-radius: calc(var(--vh, 1vh) * 1.7);
            width: 83vw;
        }
    }
}