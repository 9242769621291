.organization-info-item {
    display: flex;
    flex-direction: column;

    .title {
        font-weight: 700;
    }

    .organization-info {
        font-size: vh(1.5);
        margin: 0;
    }

    .value {
        margin-bottom: 1.5vh;
    }

    &:last-child {
        .value{
            margin-bottom: 0;
        }
    }
}