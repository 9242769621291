@import "../../style/consts.scss";

.popup-array-category {
    margin-left: 1vw;
    position: relative;
    margin-top: vh(0.8);
    margin-bottom: vh(0.8);
    display: inline-block;

    .close-category {
        width: vh(2);
        position: absolute;
        top: calc(var(--vh, 6px) * -1);
        left: calc(var(--vh, 5px) * -1);
        cursor: pointer;
    }

    .title-popup-array {
        display: flex;
        align-items: center;
        width: fit-content;
        background-color: $selected-background-inputs;
        border-radius: vh(2);
        border: 1px solid $inputs-border;
        padding: vh(0.9) vh(1.8);
        padding-bottom: vh(1.1);
        height: vh(1);
        font-size: vh(2.2);
    }

    .title-popup-array-space {
        display: flex;
        align-items: center;
        width: fit-content;
        background-color: $inputs-background-color;
        border-radius: vh(2);
        border: 1px solid $inputs-border;
        padding: 0.2vw;
        max-height: vh(2);
        font-size: vh(2);
    }
}

@media only screen and (max-width: $breakpoint) {
    .popup-array-category {
        margin-left: 4vw;

        .title-popup-array {
            padding: 1.5vw 3vw;
            font-size: vh(2.3);
        }

        .title-popup-array-space {
            width: 22vw;
            padding: 0.8vw;
            font-size: vh(2.3);
        }
    }
}
