@import "../../../style/consts.scss";

.info-bar {
    background-color: #41596b0a;
    width: 100%;
    text-align: right;
    font-size: 2.5vh;
    font-weight: 600;
    color: $texts;
    display: flex;
    word-wrap: break-word;
    word-break: break-all;

    .item-container {
        display: flex;
        align-items: center;
        gap: 1.5vw;
        margin-right: 10.8vw;
        max-width: 25vw;
        padding: 4vh 0;

        .img-container {
            display: flex;
            align-items: center;
            width: 2.126vw;

            img {
                width: 100%;
                height: auto;
            }
        }

        .text {
            white-space: pre-wrap;
            word-break: break-word;

            &.underlined {
                text-decoration: underline;
            }
        }
    }
}

.info-bar-tooltip {
    .MuiTooltip-tooltip {
        background-color: #f6f7f5;
        box-shadow: 0px 4px 7px $box-shadow;
        color: $texts;
        font-size: 2vh;
        padding: 2vh;
    }

    .MuiTooltip-arrow {
        color: #f6f7f5;
        font-size: 5vh;
    }
}

@media screen and (max-width: $breakpoint) {
    .info-bar {
        justify-content: space-around;

        .item-container {
            flex-direction: column;
            justify-content: flex-start;
            gap: 2vh;
            flex: 1;
            margin-right: 0;
            padding: 2.8vh 0;

            .img-container {
                height: 3vh;
                width: auto;

                img {
                    height: 100%;
                }
            }

            .text {
                font-size: 1.4vh;
                text-align: center;
            }
        }
    }
}
