@import "../../../style/consts.scss";

.MuiAutocomplete-root {
    direction: rtl;
    font-size: vh(1.8);

    .MuiAutocomplete-input {
        font-size: vh(1.8);
    }

    .MuiButtonBase-root {
        font-size: vh(2.4);
    }
}
