@import "../../style/consts.scss";

.popup-duplicate-event {
    height: vh(36);
    width: 40vw;

    .flex-center {
        gap: vh(5);
        margin: auto;
    }
}

@media screen and (max-width: $breakpoint) {
    .popup-duplicate-event {
        width: 70vw;
    }
}