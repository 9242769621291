@import "../../../../style/consts.scss";

.add-event-right-third {
    max-width: 85vw;
    width: 100%;
    font-size: vh(3);

    .add-event-input-title {
        font-size: vh(2.5);
        font-weight: 600;
        margin-bottom: vh(1);
    }

    .limit {
        color: $error;
        font-weight: 400;
        font-size: vh(1.5);
        text-align: right;
    }

    .shekel {
        margin: 2vh 0;
        display: flex;
        align-items: flex-end;
    }

    .file-input-container {
        position: relative;
        margin-top: vh(2);
        height: vh(4);

        .upload-file-btn {
            height: 100%;
            display: flex;
            align-items: center;
            background-color: $inputs-background-color;
            border: vh(0.15) solid $inputs-border;
            padding: 0 5%;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            min-width: 10vw;
            width: max-content;
            max-width: 12vw;

            &.long {
                width: 100%;
                max-width: unset;
            }

            .upload-text-and-logo {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: vh(1.9);
                font-weight: 400;

                img {
                    width: 1.8vh;
                    margin-left: 2%;
                }
            }
        }

        input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);
        }
    }

    .file-error {
        color: $error;
        font-size: vh(1.7);
        text-align: right;
        height: vh(1);
        margin-right: 0.5vw;
        font-weight: 400;
        margin-top: vh(0.3);
        margin-bottom: 1vh;
    }

    .seatgroup-accessibilities {
        min-width: 23vw;
    }

    .all-multiple-seatgroups-container {
        margin-top: vh(2);

        .upload-multiple {
            margin-bottom: 0.5vh;
            cursor: pointer;

            input {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                opacity: 0;
                filter: alpha(opacity=0);
            }
        }
    }

    .seatgroup-container {
        margin-bottom: vh(2);
        position: relative;
    }

    .coupon-inputs-container {
        .coupon-input {
            input {
                width: 100%;
            }
        }
    }

    .add-event-errors-third {
        color: $error;
        font-size: vh(1.7);
        text-align: right;
        margin-bottom: vh(2);
        height: vh(1);
        margin-right: 0.7vw;
    }

    .hearing-aids-input-container {
        display: flex;
        align-items: center;
        gap: 10%;
        margin-bottom: vh(2);
        flex-wrap: wrap;

        .add-event-input-title {
            margin-bottom: 0;
        }

        .add-event-errors-third {
            flex-basis: 100%;
            margin-top: vh(1);
        }
    }

    .add-event-notice {
        color: $light-gray;
        font-size: vh(1.8);
        max-width: 30vw;
    }

    .add-event-buttons-third {
        display: flex;
        justify-content: flex-end;
        margin-top: vh(4);

        .add-event-yellow-button-third {
            height: vh(6.5);
            margin-bottom: vh(5);
            margin-right: 2vw;
            height: vh(7);
            width: 11vw;
        }

        .add-event-other-button-third {
            @extend .add-event-yellow-button-third;
            text-decoration: underline;
            border: 0;
            font-family: $font;
            font-size: 1.5vw;
            font-weight: 700;
            color: $texts;
            background-color: unset;
            height: auto;
            width: auto;
        }
    }

    input:disabled {
        opacity: 0.6;
    }

    .file-input-container:has(input:disabled) {
        input {
            opacity: 0;
        }

        opacity: 0.6;
    }
}

@media screen and (max-width: $breakpoint) {
    .add-event-right-third {
        margin-bottom: vh(6);

        .tickets-sum-error {
            height: 3vh;
            margin-top: 1vh;
        }

        .limit {
            font-size: vh(1.7);
        }

        .seatgroup-container {
            margin-right: vh(-5);
            margin-bottom: 8vh;
        }

        .add-event-buttons-third {
            margin-right: 7vw;

            .add-event-yellow-button-third {
                font-size: 5vw;
                min-width: 30vw;
                border-radius: vh(4);
            }
        }
    }
}