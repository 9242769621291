@import "../../../../style/consts.scss";

@keyframes shrink-horizontal {
    from {
        width: 50vw;
    }
    to {
        width: 0vw;
        overflow: hidden;
    }
}
@keyframes shrink-vertical {
    from {
        flex: 0.6;
    }
    to {
        flex: 0;
        overflow: hidden;
    }
}

.login-container {
    display: flex;
    width: 100%;
    height: 89vh;

    .login-right-container,
    .login-left-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .login-right-content,
        .login-left-content {
            height: 50vh;
            padding-bottom: 4vh;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .login-logo {
                height: 12vh;
                margin: 0 auto 7vh;

                img {
                    height: 100%;
                }
            }

            .login-title {
                font-weight: 700;
                font-family: $font;
                font-size: vh(4);
                color: $texts;
            }
        }
    }

    .login-left-container {
        box-shadow: 0px 0px 6px $box-shadow;
        width: 50vw;
        background-color: $event-strip-background;

        &.hidden-content {
            animation-name: shrink-horizontal;
            animation-duration: 3s;
            animation-fill-mode: forwards;
        }

        .login-left-buttons {
            display: flex;
            flex-direction: column;
            gap: 5vh;
        }
    }

    .login-right-container {
        flex: 1;
        background-color: $background-color-primary;

        .login-right-content {
            .login-right-text {
                height: 2vh;
                font-size: vh(2);
                color: $texts;
            }

            .didnt-get-code {
                height: 2vh;
                background: unset;
                text-decoration: underline;
                border: unset;
                cursor: pointer;
                color: $texts;
                font-size: vh(2);
                margin: 0;
            }

            .login-right-button {
                font-size: 1.5vw;
                margin-top: vh(3);
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .login-container {
        height: 93vh;
        display: flex;
        flex-direction: column;

        .login-left-container {
            width: 100%;
            flex: 0.7;
            background-color: $background-color-left;
            box-shadow: none;

            &.hidden-content {
                animation-name: shrink-vertical;
                animation-duration: 6s;
                animation-fill-mode: forwards;
                position: relative;
            }

            .login-left-content {
                height: 25vh;

                .login-title {
                    font-size: 3vh;
                }

                .login-left-buttons {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 3vh;

                    button {
                        width: 69vw;
                        border-radius: vh(4);
                        height: vh(6);
                        font-size: vh(2.7);
                    }
                }
            }
        }

        .login-right-container {
            align-items: baseline;

            .login-right-content {
                height: 35vh;
                padding-top: 10vh;
                padding-bottom: 0;

                .login-logo {
                    height: 9vh;
                    margin-bottom: 1.5vh;
                }

                .didnt-get-code {
                    margin-bottom: vh(1);
                }

                .input {
                    height: vh(5);
                    border-radius: vh(3);
                    width: 75vw;
                }

                .login-right-button {
                    width: 62vw;
                    border-radius: vh(4);
                    height: vh(6);
                    font-size: vh(2.7);
                    margin-top: vh(1);
                }
            }
        }
    }
}
