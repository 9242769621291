@import "../../../../style/consts.scss";

.add-entrance-tickets {
    margin-bottom: vh(8);

    & > div,
    .margin-bottom {
        margin-bottom: vh(2);
    }

    .input-error {
        // @include input-error;
        min-height: 1vh;
        margin-bottom: 1vh;
    }

    .add-entrance-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 2vw;

        .continue-btn {
            margin: unset;
        }
    }

    .continue-btn {
        padding: 0 4.5vw;
        height: vh(7);
        display: block;
        margin-right: auto;
        margin-top: vh(2);
        font-size: vh(3);

        &.post-btn {
            padding: 0 1.5vw;
        }
    }

    .back-btn {
        cursor: pointer;
        color: $texts;
        text-decoration: underline;
        font-size: 1.5vw;
        font-weight: 700;
    }

    .ticket-worth-input {
        display: flex;
        gap: 0.5vw;

        input {
            width: 100%;
            align-self: flex-start;
        }

        .shekel-sign {
            font-size: vh(3);
            position: relative;
            top: 2vh;
        }
    }

    .benefit-hearing-aids {
        margin-top: 4vh;
    }
}
