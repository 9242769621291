@import "../../../style/consts.scss";

.seatgroup-accessibility-count {
    display: flex;
    align-items: center;
    margin-bottom: vh(2);
    gap: 7%;
    font-weight: 400;

    img {
        width: vh(3);
    }
}

@media screen and (max-width: $breakpoint) {
    .seatgroup-accessibility-count {
        font-size: vh(1.8);
    }
}
