@import "../../../../style/consts.scss";

.my-donations-mobile {
    background-color: $background-color-secondary;
    margin-top: 3rem;
    padding: 1rem;

    .img-container {
        position: relative;
        top: -2.625rem;
        height: 1rem;

        img {
            width: 3rem;
        }
    }
}