@import "./style/consts.scss";

[onclick]:hover {
    cursor: pointer;
}

* {
    direction: rtl;
    font-family: $font;
}
:focus-visible:not(input, textarea) {
    outline: #2074ec solid 0.5vh;
    outline-offset: 0.5vh;
    z-index: 1;
}
