@import "../../../style/consts.scss";

.user-row {
    display: flex;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: center;
    gap: 5vw;
    background-color: white;
    box-shadow: 0px 3px 16px $box-shadow-light;
    margin-right: 11vw;
    height: 10vh;
    border-radius: 2vh;
    margin-top: 2vh;
    width: 55vw;
    padding: 0 5vw;
    box-sizing: border-box;

    .user-details {
        display: flex;
        font-size: vh(2);
        // width: 80%;
        width: 100%;

        .name-user-row {
            flex: 1;
        }

        .email-details {
            overflow: hidden;
            flex: 1;
            text-overflow: ellipsis;
            direction: ltr;
        }
        .phone-details {
            flex: 1;
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .user-row {
        width: 85vw;
        align-items: center;
        justify-content: center;
        height: auto;
        padding: vh(2) 0;

        .user-details {
            flex-direction: column;
            align-items: center;
            gap: vh(2);
        }
    }
}
