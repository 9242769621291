@import "../../../../style/consts.scss";

.preview-banner {
    display: none;
}

@media screen and (max-width: $breakpoint) {
    .preview-banner {
        display: block;
        width: 100%;
        position: fixed;
        top: $header-height-mobile;
        padding: 1.3vh;
        text-align: center;
        color: $texts-secondary;
        font-size: 3vh;
        font-weight: 700;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 1;
    }
}