@import "./consts.scss";

.feedback-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $background-color-primary;
    width: 100vw;
    height: vh(89);

    .feedback-page-items {
        display: flex;
        flex-direction: column;
        align-items: center;

        .feedback-page-icon {
            height: vh(16.5);
        }

        .feedback-page-text {
            text-align: center;
            color: $dark-blue;
            font-size: vh(4);
            font-weight: bold;
            margin-top: vh(5);

            .feedback-first-line {
                font-size: vh(5);
            }
        }

        .animation-text {
            margin-top: unset;
        }

        .feedback-last-line {
            margin-top: vh(6);
        }

        .back-button {
            margin-top: vh(1);
            border-radius: vh(5);
            height: vh(8);
            width: fit-content;
            font-size: vh(3.5);
        }
    }

    .animation-items {
        margin-bottom: vh(13);
    }
}

@media screen and (max-width: $breakpoint) {
    .feedback-page {
        height: vh(92.3);

        .feedback-page-items {
            .feedback-page-icon {
                height: vh(10);
            }

            .feedback-page-text {
                font-weight: 700;
                font-size: vh(3);

                .feedback-first-line {
                    font-size: vh(4);
                    margin: 0 2vw
                }

                .feedback-last-line {
                    margin-right: 5vw;
                    margin-left: 5vw;
                }
            }

            .back-button {
                height: vh(6.5);
                width: 80vw;
                border-radius: vh(4);
                font-size: vh(3);
            }
        }
    }
}