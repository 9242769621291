@import "./consts.scss";

.term-div {
    max-width: 27vw;
    margin-right: 0.5vw;
    font-size: 2.5vh;
}

.signup-right-errors {
    color: $error;
    font-weight: 400;
    font-size: vh(1.7);
    text-align: right;
    margin-bottom: vh(1);
    height: vh(1);
}

@media screen and (max-width: $breakpoint) {
    .term-div {
        max-width: 75vw;
        font-size: 1.8vh;
    }
}