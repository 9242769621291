@import "../../../../style/consts.scss";

.multiple-seatgroup-container {
    width: 24vw;
    height: vh(33);
    margin-bottom: 3vh;

    .seatgroup-number-title {
        font-size: vh(2.5);
        font-weight: 600;
        color: $texts;
        margin-bottom: vh(0.5);
    }

    .seatgroups-inputs {
        display: flex;
        flex-direction: row;
        gap: 10%;

        .seatgroup-file-container {
            .first-error {
                color: $texts;
                font-size: vh(1.7);
                text-align: right;
                min-height: vh(1);
                font-weight: 400;
                margin-top: vh(0.3);

                &.error {
                    color: $error;
                }
            }
        }
    }

    .accessibilities-container {
        .add-event-input-title {
            font-size: vh(2.2);
            margin-bottom: vh(1);
        }
    }
}

@media screen and (max-width: $breakpoint) {
    .multiple-seatgroup-container {
        width: auto;
        justify-content: space-between;
        gap: 3vw;

        .seatgroups-inputs {
            width: 100%;
            justify-content: space-between;

            .input {
                width: 33vw;
            }

            .seatgroup-file-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: max-content;

                .file-input-container {
                    .upload-file-btn {
                        border-radius: vh(1.7);
                        justify-content: center;
                        max-width: unset;
                        width: 40vw;

                        .upload-text-and-logo {
                            margin-right: 4%;
                        }
                    }

                    input {
                        width: 100%;
                    }
                }

                .first-error {
                    font-size: vh(1.7);

                    &.error {
                        margin-top: -2vh;
                    }
                }
            }
        }

        .add-event-input-title {
            margin-right: 1vw;
        }
    }
}