@import "../../style/consts.scss";

.load-draft-event-popup {
    width: 40vw;
    height: 40vh;

    .draft-popup-content {
        margin-top: 2vh;

        .first-title,
        .second-title {
            color: $texts;
            font-size: vh(3);
            font-weight: 600;
        }

        .draft-popup-btn {
            padding: 0.8vh 1.6vh;
            margin: 6vh 1vh;
        }
    }
}

@media (max-width: $breakpoint) {
    .load-draft-event-popup {
        width: 75vw;
        height: 60vh;

        .draft-popup-content {
            .second-title {
                margin-top: vh(2);
                margin-bottom: vh(5);
            }

            .first-title,
            .second-title {
                padding: 4vw;
                font-size: vh(2.5);
            }

            .draft-popup-btn {
                padding: 1vh 2vw;
                margin: 1vh;
                font-size: 2vh;
                width: 55vw;
            }
        }
    }
}