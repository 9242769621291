@import "../../style/consts.scss";

.popup-event-preview {
    height: 48vh;
    width: 55vw;

    .text-wrapper {
        font-size: vh(3);
        font-weight: 500;
        margin-bottom: 5vh;
        color: $dark-blue;
    }

    .text-wrapper>p {
        margin: 0.8vh;
    }

    .buttons-wrapper {
        display: flex;
        flex-direction: row;
        gap: 2vw;
    }

    .the-button {
        width: 20vw;
    }
}

@media (max-width: $breakpoint) {
    .popup-event-preview {
        height: 48vh;
        width: 84vw;

        .text-wrapper {
            font-size: vh(2.5);
            margin-bottom: 4vh;
        }

        .buttons-wrapper {
            display: flex;
            flex-direction: column;
            gap: 3vh;
        }

        .the-button {
            width: 30vw;
        }
    }
}