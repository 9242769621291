@import "../../../../style/consts.scss";

.future-purchased-events-list {
    @include content-pages-section-styles;
    background-color: $background-color-primary;
    height: vh(82);
}

@media screen and (max-width: $breakpoint) {
    .future-purchased-events-list {
        height: vh(85.5);
    }
}