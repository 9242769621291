@import "./consts.scss";

.go-back {
    font: normal normal normal 1.853vh/2.409vh Assistant;
    color: $texts;
    margin-right: 6vw;

    .all-go-back {
        height: 0;
        margin-top: vh(4);
        margin-bottom: vh(-4);
        display: flex;
        align-items: center;
        .arrow-img-go-back {
            .img-go-back {
                width: vh(2);
            }
        }
        .go-back-text {
            font-size: 1.2vw;
            font-weight: 600;
            cursor: pointer;
        }
    }
}

.with-background {
    background-color: $background-color-left;
}

@media screen and (max-width: $breakpoint) {
    .go-back {
        .all-go-back {
            margin-bottom: vh(-2);

            .go-back-text {
                font-size: 4.5vw;
            }
        }
    }
}
