@import "../../../../style/consts.scss";

.org-home-stats-container {
    background-color: white;
    padding: 4vh 0;
    height: 30vh;
    display: flex;
    align-items: center;
    color: $texts;

    &.no-data {
        display: none;
    }

    .send-feedback {
        flex: 1;
        font-size: 2.5vh;
        font-weight: 600;
        text-align: center;

        .send-feedback-btn {
            padding: 1vh 3vh;
            margin-top: 2vh;
            width: 20vw;

            .items-in-feedback-button {
                display: flex;
                align-items: center;

                img {
                    height: 2vh;
                    margin-right: 2vh;
                }
            }
        }
    }

    hr {
        border: #70707018 1px solid;
        height: inherit;
    }

    .org-stats {
        flex: 1.8;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .single-stat {
            text-align: center;
            font-size: 2.5vh;

            img {
                height: 8vh;
                margin-bottom: 2.5vh;
            }

            .number-container {
                font-size: 5vh;
                font-weight: 700;
            }
        }
    }
}

@media (max-width: $breakpoint) {
    .org-home-stats-container {
        flex-direction: column;
        height: vh(69);

        .send-feedback {
            font-size: vh(2.4);
            width: 60vw;
            font-weight: 700;

            .send-feedback-btn {
                padding-right: 9vw;
                padding-left: 9vw;
                font-size: vh(2);
                width: 55vw;

                .items-in-feedback-button {
                    width: 50vw;
                    justify-content: center;
                    gap: 1vw;

                    img {
                        height: 1.5vh;
                        margin-right: 1vh;
                    }
                }
            }
        }

        .org-stats {
            gap: 4vw;
            margin-top: vh(2);
            flex-direction: column;

            .single-stat {
                text-align: center;
                font-size: vh(2);
                margin-bottom: vh(3.5);

                img {
                    height: vh(5.5);
                    margin-bottom: 0;
                }

                .number-container {
                    font-weight: 700;
                    font-size: vh(4);
                }
            }
        }
    }
}